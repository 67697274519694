import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
} from "react";
import { useApi } from "../../utils/hooks/useAxiosPrivate";
import { useInterceptorsContext } from "./InterceptorsContext";
import { CatProvider } from "./CategoryContext";
import { ClassProvider } from "./ClassContext";

interface Restaurant {
  rest_site_id: number;
  rest_name: string;
  rest_nipc: number | null;
  rest_address: string | null;
  rest_city: string | null;
  rest_contact_phone: string | null;
  rest_country: string | null;
  rest_email: string;
  setup: "NEW" | "PROGRESS" | "COMPLETED";
  rest_latitude: number | null;
  rest_longitude: number | null;
  rest_postal_code: string | null;
  ztimestamp: string;
}

interface Profile {
  profile_id: number;
  first_name: string;
  last_name: string;
  phone: string | null;
  rest_site: number;
  restaurant: Restaurant;
  setup: "NEW" | "PROGRESS" | "COMPLETED";
  role: "MANAGER" | "CHEF" | "SALES";
  subscription: "FREE" | "NOT_FREE";
  user_id: number;
  ztimestamp: string;
}

interface User {
  id: number;
  email: string;
  profile: Profile;
  restaurant: Restaurant;
  username: string;
}

interface UserContextType {
  user: User | null;
  restaurant: Restaurant | null; // Added restaurant
  profile: Profile | null; // Added profile
  loading: boolean;
  groups: string[] | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  setRestaurant: React.Dispatch<React.SetStateAction<Restaurant | null>>;
  setProfile: React.Dispatch<React.SetStateAction<Profile | null>>;
  setGroups: React.Dispatch<React.SetStateAction<string[] | null>>;
  activeSubscription: boolean;
  fetchUser: () => Promise<void>;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [restaurant, setRestaurant] = useState<Restaurant | null>(null);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [groups, setGroups] = useState<string[] | null>(null);
  const [activeSubscription, setActiveSubscription] = useState<boolean>(false);
  const { get } = useApi();
  const { interceptorsReady } = useInterceptorsContext();
  const [loading, setLoading] = useState<boolean>(true); // starts true

  const fetchUser = async () => {
    try {
      const [userResponse, subscriptionResponse] = await Promise.all([
        get("user/"),
        get("has-active-subscription/"),
      ]);

      if (userResponse.status === 200) {
        setRestaurant(userResponse.data.restaurant);
        setProfile(userResponse.data.profile);
        setUser(userResponse.data.user);
        if (Array.isArray(userResponse.data.groups)) {
          setGroups(userResponse.data.groups);
        } else {
          setGroups(null);
        }
      }

      setActiveSubscription(subscriptionResponse.data);
    } catch (error) {
      console.error("Error fetching user or subscription data:", error);
    }
  };

  useEffect(() => {
    if (!user && interceptorsReady) {
      //setLoading(true);
      fetchUser().finally(() => setLoading(false));
    }
  }, [user, interceptorsReady]);
  
  return (
    <UserContext.Provider
      value={{
        user,
        restaurant,
        profile,
        groups,
        activeSubscription,
        loading,
        setUser,
        setRestaurant,
        setProfile,
        setGroups,
        fetchUser,
      }}
    >
      {user ? (
        <CatProvider>
          <ClassProvider>
            {children}
          </ClassProvider>
        </CatProvider>
      ) : (
        <>{children}</>
      )}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  return context;
};