import { Link } from "react-router-dom";

const NotFoundPage: React.FC = () => {
  return (
    <div className="h-[calc(100vh-2.5rem)] pt-10 overflow-hidden">
        <div className="relative isolate h-full flex items-center justify-center"  style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/background.webp)`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
          <div className="w-1/2">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <section className="bg-white">
                <div className="mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                  <div className="mx-auto max-w-screen-sm text-center">
                    <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-purple">404</h1>
                    <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl">Cozinha Errada!</p>
                    <p className="mb-4 text-lg font-light text-gray-500">A pagina que tentou entrar não existe ou está em manutenção. </p>
                    <Link to="/app/homepage" className="inline-flex text-black bg-purple rounded-xl text-sm px-5 py-2.5 text-center transition-all duration-500 hover:scale-110">Volte à página principal.</Link>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
    </div>
  );
};

export default NotFoundPage;