import React from "react";
import { IoIosSearch } from "react-icons/io";
import { Input } from "./input";
import { Button } from "./button";

interface SearchBarProps {
  search: string;
  setSearch: (value: string) => void;
  setFetchSearch: (value: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({
  search,
  setSearch,
  setFetchSearch,
}) => {
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setFetchSearch(search);
  };

  return (
    <form onSubmit={handleSubmit} className="flex gap-2">
      <Input
        type="text"
        className="w-[300px] h-10"
        name="search"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <Button type="submit">
        <IoIosSearch className="text-white" size={25} />
      </Button>
    </form>
  );
};

export default SearchBar;
