// React
import { useCallback, useEffect, useRef, useState } from "react";

// Contexts
import useDarkMode from "../../Profile/usedarkmode";
import { useCat } from "../../../utils/contexts/CategoryContext";
import { useApi } from "../../../utils/hooks/useAxiosPrivate";
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";

// Components
import Page from "../page";
import ChartComponent from "../../../components/reusable/charts/chartComponent";
import SelectComponent from "../../../components/reusable/select/select";
import NWeeksHelp from "../../../components/reusable/NWeeksHelp";
import CategoryFormatter from "../../../components/reusable/select/categories";
import NWeeksFormatter from "../../../components/reusable/select/nweeks";
import Loading from "../../../components/reusable/loading/loading";
import { useLoading } from "../../../utils/contexts/LoadingContext";
import {
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card"; // Import Card components

// Icons
import { IoPricetag } from "react-icons/io5";
import { FaChartBar, FaTable } from "react-icons/fa";
import MyTable from "../../../components/reusable/table/table-component";
const INITIAL_COLUMNS = [
  { headerName: "Name", field: "name" },
  { headerName: "Category", field: "category" },
  { headerName: "Initial Price", field: "price_ini" },
  { headerName: "Finakl Price", field: "price_fim" },
  { headerName: "Variation", field: "variation" },
];
// Constants
const INITIAL_CHART_STATE = {};

const INITIAL_WEEKS = 4;

const D3FoodCostRecipesPage = () => {
  const [tokenVerified, setTokenVerified] = useState(false);
  const { Categories } = useCat();
  const { get } = useApi();
  const darkMode = useDarkMode();
  const [seeChart, setSeeChart] = useState(true);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "FOODCOSTRECIPES PAGE",
  });

  const [chartData, setChartData] = useState({
    q11: INITIAL_CHART_STATE,
    q12: INITIAL_CHART_STATE,
    q21: INITIAL_CHART_STATE,
    q22: INITIAL_CHART_STATE,
    q31: INITIAL_CHART_STATE,
    q41: INITIAL_CHART_STATE,
    q42: INITIAL_CHART_STATE,
    q42_table: [],
  });
  const [nweeks, setNweeks] = useState(INITIAL_WEEKS);
  const [category, setCategory] = useState("");
  const { loading, setLoading } = useLoading();
  const [chartLoaded, setChartLoaded] = useState(false);
  const chartRef = useRef(null);

  function handleChartFinished() {
    if (chartRef.current) {
      chartRef.current.getEchartsInstance().resize();
    }
    setChartLoaded(true);
  }

  useEffect(() => {
    if (Categories.length > 0) {
      setCategory(Categories[0]);
    }
  }, [Categories]);

  const fetchChartData = useCallback(async (endpoint, params = {}) => {
    const queryString = new URLSearchParams(params).toString();
    const url = `dash3_${endpoint}/${queryString ? `?${queryString}` : ""}`;

    try {
      setLoading(true);
      const response = await get(url);
      setChartData((prevData) => ({
        ...prevData,
        [endpoint]: response.data,
      }));

      console.log(`Fetched data for ${endpoint}:`, response.data);
    } catch (error) {
      console.error(`Error fetching data for ${endpoint}:`, error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
      const endpoints = ["q11", "q12", "q21", "q22", "q31", "q32", "q41", "q42", "q42_table"];
      endpoints.forEach((endpoint) => {
        if ((endpoint.startsWith("q41") || endpoint.startsWith("q31"))) {
          const params = { nweeks, category };
          if (category !== "") fetchChartData(endpoint, params);
        } else {
          const params = { nweeks };
          fetchChartData(endpoint, params);
        }
      });
    }, [nweeks, category, fetchChartData]);

  const renderCharts = () => {
    const chartConfigs = [
      { option: chartData.q11 },
      { option: chartData.q12 },
      { option: chartData.q21 },
      { option: chartData.q22 },
    ];

    return (
      <div className="flex flex-col gap-4">
        <div className="grid xl:grid-cols-2 gap-4 ">
          {chartConfigs.map((config, index) => (
            <ChartComponent
              key={index}
              handleChartFinished={handleChartFinished}
              chartRef={chartRef}
              option={config.option}
              ChangeScale={false}
            />
          ))}
        </div>
        <div className="py-4 flex gap-6 items-center">
          <FaChartBar className={`cursor-pointer transition-all duration-300 ease-in-out`} color={seeChart ? "#484c9c" : "black"} onClick={() => setSeeChart(true)} size={36} title="Ver Gráfico" />
          <FaTable className={`cursor-pointer transition-all duration-300 ease-in-out`} color={!seeChart ? "#484c9c" : "black"} onClick={() => setSeeChart(false)} size={34} title="Ver Tabela" />
        </div>
        <div className="grid grid-cols-1 gap-4 ">
          {seeChart ?
            <ChartComponent
              handleChartFinished={handleChartFinished}
              chartRef={chartRef}
              option={chartData.q42}
              ChangeScale={false}
            />
            :
            <MyTable
              rowData={chartData.q42_table}
              columnDefs={INITIAL_COLUMNS}
              title="Produtos com maior variação"
              darkMode={darkMode}
            />
          }
        </div>
      </div>
    );
  };

  const renderCategoryCharts = () => {
    if (Categories.length === 0) return null;

    return (
      <>
        <div className="flex flex-inline justify-between mb-5 w-full pt-10 items-center">
          <CardHeader className="mt-10 mb-5">
            <CardTitle>Análise por Categoria</CardTitle>
            <CardDescription>
              Variações de preço por categoria de produto do seu restaurante
            </CardDescription>
          </CardHeader>
          <div className="mt-5 mil:pt-0">
            <SelectComponent
              formatter={CategoryFormatter}
              darkMode={darkMode}
              onChange={setCategory}
            />
          </div>
        </div>
        <div className="grid xl:grid-cols-2 gap-4 ">
          <ChartComponent
            chartRef={chartRef}
            option={chartData.q41}
            ChangeScale={false}
          />
          <ChartComponent
            handleChartFinished={handleChartFinished}
            chartRef={chartRef}
            option={chartData.q31}
            ChangeScale={false}
          />
        </div>
      </>
    );
  };

  return (
    <Page title={"Visão de Receitas"} loading={loading}>
      {tokenVerified ? (
        <div>
          <div className="padding">
            <div className="flex flex-inline justify-between mb-5">
              <CardHeader>
                <CardTitle>Visão Receitas e Ingredientes</CardTitle>
                <CardDescription>
                  Acompanhe os custos das receitas e ingredientes utilizados no
                  seu restaurante.
                </CardDescription>
              </CardHeader>
              <div className="mt-5 mil:pt-0">
                <SelectComponent
                  formatter={NWeeksFormatter}
                  darkMode={darkMode}
                  onChange={setNweeks}
                />
              </div>
            </div>
            {renderCharts()}
            {renderCategoryCharts()}
          </div>
          <Loading isOpen={loading} />
        </div>
      ) : (
        // <Loading isOpen={true} />
        <></>
      )}
    </Page>
  );
};
export default D3FoodCostRecipesPage;
