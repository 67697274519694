import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../../../../utils/hooks/useAxiosPrivate";
import Toaster from "../../../../components/reusable/Toaster";
import { Input } from "../../../../components/ui/input";
import { Label } from "../../../../components/ui/label";
import { Button } from "../../../../components/ui/button";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { useLoading } from "../../../../utils/contexts/LoadingContext";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../../../components/ui/avatar";
import { Card } from "../../../../components/ui/card";

import { useUser } from "../../../../utils/contexts/UserContext";

interface ProfileSetupProps {
  onPrevious?: () => void;
  onSubmit?: () => void;
}

const ProfileSetup: React.FC<ProfileSetupProps> = ({
  onPrevious,
  onSubmit,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const { loading, setLoading } = useLoading();
  const [change, setChange] = useState(false);
  const { user } = useUser() || {};
  const [profile, setProfile] = useState<any>({});
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    const getProfile = async () => {
      setLoading(true);
      try {
        const response = await axiosPrivate.get("profile");
        const profileData = Array.isArray(response.data)
          ? response.data[0]
          : response.data;
        setProfile(profileData);
        setFirst_name(profileData.first_name ?? "");
        setLast_name(profileData.last_name ?? "");
        setPhone(profileData.phone ?? "");
      } catch (error) {
        console.error("error", error);
      }
      setLoading(false);
    };

    getProfile();
  }, [change]);

  const isValidPhoneNumber = (number: string) => {
    const phoneNumber = parsePhoneNumberFromString(number, "PT");
    return phoneNumber ? phoneNumber.isValid() : false;
  };

  const handleProfileSubmit = async () => {
    setLoading(true);

    const data = {
      first_name: first_name,
      last_name: last_name,
      phone: phone,
      setup: "COMPLETED",
    };

    if (!isValidPhoneNumber(phone)) {
      Toaster.show("Número de telemóvel inválido.", "error");
      setLoading(false);
      return;
    }

    try {
      const response = await axiosPrivate.post(
        "/profile-update/",
        JSON.stringify(data)
      );

      if (response.status === 200 || response.status === 201) {
        Toaster.show("Perfil atualizado com sucesso.", "success");
        if (onSubmit) {
          onSubmit();
        }
        setChange(!change);
      } else {
        Toaster.show("Erro ao atualizar perfil.", "error");
      }
    } catch (error) {
      Toaster.show("Erro ao atualizar perfil.", "error");
    }
    setLoading(false);
  };

  return (
    <Card className="p-6">
      <h2 className="text-xl font-semibold mb-6">
        Informação do Utilizador
        {user?.username && (
          <span className="text-blue-800 ml-2">
            {user.username}
          </span>
        )}
      </h2>
      <div className="space-y-8">
        <div className="flex items-center gap-6">
          <Avatar className="h-20 w-20">
            <AvatarImage src="https://github.com/shadcn.png" />
            <AvatarFallback>CN</AvatarFallback>
          </Avatar>
          <Button>Alterar Avatar</Button>
        </div>

        <div className="grid gap-4 max-w-lg">
          <div className="space-y-2">
            <Label htmlFor="firstName">Primeiro Nome</Label>
            <Input
              id="firstName"
              placeholder="Primeiro nome"
              value={first_name}
              onChange={(e) => setFirst_name(e.target.value)}
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="lastName">Último Nome</Label>
            <Input
              id="lastName"
              value={last_name}
              placeholder="Último nome"
              onChange={(e) => setLast_name(e.target.value)}
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="phone">Nº Telemóvel</Label>
            <Input
              id="phone"
              type="tel"
              placeholder="+351"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
        </div>
        <Button
          className="w-full"
          onClick={handleProfileSubmit}
          disabled={loading || !first_name || !last_name || !phone}
        >
          Guardar Alterações
        </Button>
      </div>
    </Card>
  );
};

export default ProfileSetup;
