// React
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// contexts
import { DarkModeProvider } from "../../../utils/contexts/theme.context";
import { useLoading } from "../../../utils/contexts/LoadingContext";

// components
import Page from "../page";
import Loading from "../../../components/reusable/loading/loading";
import InfoComponent from "../../../components/reusable/info/info.component";
import Toaster from "../../../components/reusable/Toaster";
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";
import useDarkMode from "../../Profile/usedarkmode";
import { useApi } from "../../../utils/hooks/useAxiosPrivate";
import { Button } from "../../../components/ui/button";
import { Label } from "../../../components/ui/label";

import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";
import {
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";

// icons / images
import { IoPlayForwardCircle } from "react-icons/io5";
import { MdSearch } from "react-icons/md";
import { Description } from "@headlessui/react";

const initialColumns = [
  { headerName: "Nome", field: "an_nome" },
  { headerName: "Descrição", field: "an_descr" },
  { headerName: "Estado", field: "an_obs" },
  { headerName: "Data de início", field: "an_dt_ini" },
  { headerName: "Data de fim", field: "an_dt_fim" },
  { headerName: "", field: "" },
];

const initialNewForecastData = {
  an_name: "",
  an_decrip: "",
};

const ForecastAnalysis = () => {
  const [tokenVerified, setTokenVerified] = useState(false);
  const navigate = useNavigate();

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "FORECAST ANALYSIS PAGE",
  });

  const { get, post } = useApi();
  const darkMode = useDarkMode();

  const [forecast, setForecast] = useState(null);
  const [forecastAvailable, setForecastAvailable] = useState(false);
  const { loading, setLoading } = useLoading();
  const [showModal, setShowModal] = useState(false);
  const [newForecastData, setNewForecastData] = useState(
    initialNewForecastData
  );

  useEffect(() => {
    const controller = new AbortController();

    const fetchData = async () => {
      try {
        const [forecastResponse, availabilityResponse] = await Promise.all([
          get("list_forecasts", { signal: controller.signal }),
          get("forecast_requirements"),
        ]);

        setForecast(forecastResponse.data);
        setForecastAvailable(availabilityResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Toaster.show("Erro ao carregar dados de forecast", "error");
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    return () => controller.abort();
  }, []);

  const handleCreateForecast = async () => {
    const newForecast = {
      an_descr: newForecastData.an_decrip,
      an_nome: newForecastData.an_name,
      an_obs: "Em progresso...",
    };

    try {
      const response = await post(
        `new_forecast/?name=${newForecastData.an_name}&description=${newForecastData.an_decrip}`,
        JSON.stringify(newForecastData)
      );
      Toaster.show("Forecast criado com sucesso!", "success");
      setShowModal(false);
      setForecast((prevForecast: any) => [...prevForecast, newForecast]);
      console.log("Created ", response);
    } catch (error) {
      console.error("Erro ao criar forecast:", error);
      Toaster.show("Erro ao criar forecast", "error");
    } finally {
      setShowModal(false);
    }
    setNewForecastData(initialNewForecastData);
  };

  const renderModalContent = () => {
    if (!forecastAvailable) {
      return (
        <>
          <InfoComponent>
            <p className="text-justify">
              A criação do forecast necessita de dados mais recentes. Por favor
              carregue os seus ficheiros de faturação até à pelo menos 6
              semanas.
            </p>
          </InfoComponent>
          <div className="flex justify-between gap-x-3">
            <Link to="/app/uploadsaft" className="w-full">
              <Button variant="outline">Carregar Dados</Button>
            </Link>
            <Button onClick={() => setShowModal(false)}>Cancelar</Button>
          </div>
        </>
      );
    }
    return (
      <>
        <div className="mb-4 flex">
          <div className="w-1/2 pr-2">
            <Label className="block text-gray-700 text-sm font-bold mb-2">
              Nome
            </Label>
            <input
              placeholder="Nome do Forecast"
              className="w-full bg-gray-200 border rounded py-2 px-3"
              type="text"
              value={newForecastData.an_name}
              onChange={(e) =>
                setNewForecastData({
                  ...newForecastData,
                  an_name: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="mb-4">
          <Label className="block text-gray-700 text-sm font-bold mb-2">
            Descrição
          </Label>
          <textarea
            placeholder="Descrição do Forecast"
            className="w-full bg-gray-200 border rounded py-2 px-3"
            value={newForecastData.an_decrip}
            onChange={(e) =>
              setNewForecastData({
                ...newForecastData,
                an_decrip: e.target.value,
              })
            }
          />
        </div>
        <div className="flex justify-end gap-x-3">
          <Button
            onClick={handleCreateForecast}
            disabled={!newForecastData.an_name || !newForecastData.an_decrip}
          >
            Criar
          </Button>
        </div>
      </>
    );
  };

  const renderForecastTable = () => (
    <Table>
      <TableHeader>
        <TableRow>
          {initialColumns.map((column) => (
            <TableCell key={column.field}>{column.headerName}</TableCell>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {forecast.map((item, index) => (
          <TableRow key={index}>
            <TableCell>{item.an_nome}</TableCell>
            <TableCell>{item.an_descr}</TableCell>
            <TableCell>{item.an_obs}</TableCell>
            <TableCell>{item.an_dt_ini}</TableCell>
            <TableCell>{item.an_dt_fim}</TableCell>
            <TableCell>
              {item.an_obs === "Disponível" ? (
                <MdSearch
                  size={24}
                  className="cursor-pointer"
                  onClick={() => {
                    if (item.an_obs === "Disponível") {
                      navigate(`/app/forecasts/forecast?=${item.an_id}`, {
                        state: {
                          ingr_id: item.an_id,
                          name: item.an_nome,
                          description: item.an_descr,
                        },
                      });
                    }
                  }}
                />
              ) : (
                ""
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  return (
    <Page loading={loading}>
      {tokenVerified ? (
        <div className="w-[90%] mx-auto min-h-screen">
          <div className="flex justify-between items-center mb-5">
            <CardHeader>
              <CardTitle>Análise de Forecast</CardTitle>
              <CardDescription>
                Crie e gerencie suas análises de forecast
              </CardDescription>
            </CardHeader>
            <Dialog open={showModal} onOpenChange={setShowModal}>
              <DialogTrigger asChild>
                <Button className="mt-5">+ Criar Forecast</Button>
              </DialogTrigger>
              <DialogContent className="min-w-[700px] max-h-[800px] overflow-auto">
                <DialogTitle>Criar Novo Forecast</DialogTitle>
                <DialogDescription>
                  Preencha os campos para criar um novo forecast
                </DialogDescription>
                {renderModalContent()}
              </DialogContent>
            </Dialog>
          </div>
          <div>
            {forecast && forecast.length > 0 ? (
              renderForecastTable()
            ) : (
              <div
                style={{ color: darkMode ? "white" : "black" }}
                className="text-center text-2xl mt-10"
              >
                Sem forecasts. Crie um novo forecast.
              </div>
            )}
          </div>
          <Loading isOpen={loading} />
        </div>
      ) : (
        // <Loading isOpen={true} />
        <></>
      )}
    </Page>
  );
};

export default ForecastAnalysis;
