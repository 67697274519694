// src/features/queries/pages/IndividualQuery.tsx
import React, { useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useTokenVerifier from "../../../../utils/hooks/useTokenVerifier";
import useDarkMode from "../../../Profile/usedarkmode";
import { useLoading } from "../../../../utils/contexts/LoadingContext";

import Page from "../../page";
import { QueryFilters } from './QueryFilters';
import { ChartModal } from './ChartModal';
import { getQueryConfig, requiresDateSearch } from './QueryConfigs';
import { DatePicker } from '../../../../components/ui/datePicker';
// import SearchBar from '../../../../components/ui/searchbar';
import { Table, TableHeader, TableBody, TableRow, TableCell } from "../../../../components/ui/table";

import Loading from '../../../../components/reusable/loading/loading';
import Paginate from '../../../../components/reusable/pagination/paginate';
import Toaster from '../../../../components/reusable/Toaster';
import { FaArrowCircleLeft, FaSearch } from 'react-icons/fa';
import RangeFormatter from "../../../../components/reusable/select/range";
import {
    CardDescription,
    CardHeader,
    CardTitle,
} from "../../../../components/ui/card";
import { useApi } from "../../../../utils/hooks/useAxiosPrivate";


const query8ColumnsDrillDown = [
    { headerName: "Dia de Acesso", field: "day" },
    { headerName: "Número de Acessos", field: "num_accesses" },
];

const query8ColumnsDrillDownFunc = [
    { headerName: "Funcionalidade Acedida", field: "functionality" },
    { headerName: "Número de Acessos", field: "num_accesses" },
];

export const convertDateToYYYYMMDD = (date: string): string => {
    const dateObj = new Date(date);


    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    return `${year}${month}${day}`;
};

export const formatDate = (dateString: string, hour = true): string => {
    const date = new Date(dateString);
    if (!hour) {
        return date.toLocaleDateString("pt-PT");
    }
    return date.toLocaleDateString("pt-PT", {
        hour: "numeric",
        minute: "numeric",
    });
};

interface LocationState {
    nConsulta: number;
}


const IndividualQuery: React.FC = () => {
    // Router and Location
    const location = useLocation();
    const navigate = useNavigate();
    const { nConsulta } = (location.state as LocationState) || {};

    // Token Verification
    const [tokenVerified, setTokenVerified] = useState(false);
    useTokenVerifier({
        onTokenVerified: () => setTokenVerified(true),
        log: "QUERY PAGE",
    });

    // Theme
    const darkMode = useDarkMode();

    // Global Loading State
    const { loading, setLoading } = useLoading();
    const { get } = useApi();

    // Local State
    // const [search, setSearch] = useState("");
    const [searchItem, setSearchItem] = useState("");
    const [category, setCategory] = useState("Bebidas");
    const [priceRange, setPriceRange] = useState("<= 1€");
    const [prov, setProv] = useState("Pingo Doce");
    const [showModal, setShowModal] = useState(false);
    const [itemToFetch, setItemToFetch] = useState<string | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [chartData, setChartData] = useState(null);
    const [chartMessage, setMessage] = useState("");
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [drillData, setDrillData] = useState([]);
    const [drillDataFunc, setDrillDataFunc] = useState([]);
    const [showRowIndex, setShowRowIndex] = useState(-1);
    const [drillToDisplay, setDrillToDisplay] = useState(null);

    // Date handling
    const today = new Date();
    const [date, setDate] = useState(new Date());

    // Chart reference
    const chartRef = useRef(null);
    const [chartLoaded, setChartLoaded] = useState(false);

    // Get query configuration
    const queryConfig = getQueryConfig(nConsulta);
    const needsDateSearch = requiresDateSearch(nConsulta);

    // Prepare query parameters
    const queryParams = React.useMemo(() => {
        if (queryConfig?.showCategory) {
            return {
                page: currentPage,
                category,
                price_range: RangeFormatter.rangeToCategory(priceRange),
                prov_name: prov,
            };
        }

        return {
            page: currentPage,
            search: needsDateSearch ? convertDateToYYYYMMDD(date.toISOString()) : searchItem,
        };
    }, [currentPage, category, priceRange, prov, date, searchItem, needsDateSearch]);



    // Handle chart load completion
    const handleChartFinished = () => {
        if (chartRef.current) {
            chartRef.current.getEchartsInstance().resize();
        }
        setChartLoaded(true);
    };

    const handleSetShowRowIndex = (index: number) => {
        setDrillToDisplay(null);
        if (showRowIndex === index) {
            setShowRowIndex(-1);
            setDrillData([]);
            setDrillDataFunc([]);
        } else {
            setShowRowIndex(index);
        }
    }

    //used for query 8 -> user logs
    const renderTableDrill = (drillToDisplay) => {
        if (drillToDisplay === 'drilldown') {
            return (
                <Table>
                    <TableHeader>
                        <TableRow>
                            {query8ColumnsDrillDown.map((column, index) => (
                                <TableCell
                                    key={index}
                                >
                                    {column.headerName}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {drillData.map((row, rowIndex) => (
                            <TableRow key={rowIndex}>
                                {query8ColumnsDrillDown.map((column, colIndex) => (
                                    <TableCell key={colIndex}>
                                        {row[column.field]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            );
        } else if (drillToDisplay === 'drilldownFunc') {
            return (
                <Table>
                    <TableHeader>
                        <TableRow>
                            {query8ColumnsDrillDownFunc.map((column, index) => (
                                <TableCell
                                    key={index}
                                >
                                    {column.headerName}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {drillDataFunc.map((row, rowIndex) => (
                            <TableRow key={rowIndex}>
                                {query8ColumnsDrillDownFunc.map((column, colIndex) => (
                                    <TableCell key={colIndex}>
                                        {row[column.field]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            );
        } else {
            return null;
        }
    }

    // Redirect if no query number
    React.useEffect(() => {
        if (nConsulta == null) {
            navigate("/app/homepage");
        }
    }, [nConsulta, navigate]);

    React.useEffect(() => {
        const controller = new AbortController();

        const fetchData = async () => {
            setLoading(true);
            try {
                const queryParamsString = new URLSearchParams(
                    Object.entries(queryParams).reduce((acc, [key, value]) => {
                        acc[key] = String(value);
                        return acc;
                    }, {} as Record<string, string>)
                ).toString();
                const response = await get(
                    `c1_q${nConsulta}/?${queryParamsString}`,
                    { signal: controller.signal }
                );

                setData(response.data.data);
                console.log("queryData", response.data.data);
                setTotalPages(response.data.total_pages);
                setLoading(false);
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };

        if (nConsulta) {
            fetchData();
        }

        return () => controller.abort();
    }, [nConsulta, queryParams]);

    React.useEffect(() => {
        const controller = new AbortController();
        const endpoint = nConsulta == 4 ? 'c4_grf' : 'c1_grf1';

        const fetchChartData = async () => {
            setLoading(true);
            try {
                const params = nConsulta === 4
                    ? { ingre_id: itemToFetch }
                    : { sku_id: itemToFetch };

                const response = await get(
                    `${endpoint}/?${new URLSearchParams(params)}`,
                    { signal: controller.signal }
                );

                if (response.data.message) {
                    setMessage(response.data.message);
                    return;
                }

                setChartData(response.data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };
        if (itemToFetch && nConsulta != 8) {
            fetchChartData();
        }

        return () => controller.abort();
    }, [itemToFetch, nConsulta]);

    // Show/hide modal based on product data
    React.useEffect(() => {
        if (chartMessage) {
            setShowModal(false);
            Toaster.show(chartMessage, "error");
        } else if (chartData && itemToFetch) {
            setShowModal(true);
        }
    }, [chartData, itemToFetch, chartMessage]);

    // Navigation
    const handleGoBack = () => navigate(-1);

    //ONLY FOR QUERY 8 BackOffice -> Logs 
    React.useEffect(() => {
        const fetchDrill_query8 = async () => {
            setLoading(true);
            try {
                const [response1, response2] = await Promise.all([
                    get(`c1_q8_drilldown/?user=${itemToFetch}`), //a username
                    get(`c1_q8_functionality_drilldown/?user=${itemToFetch}`) //a username
                ]);

                setDrillData(response1.data.data);
                setDrillDataFunc(response2.data.data);
                setLoading(false);
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };

        if (nConsulta == 8 && itemToFetch) {
            fetchDrill_query8();
        }

    }, [nConsulta, itemToFetch]);


    if (!queryConfig) {
        return null;
    }


    return (
        <Page title="Consulta" loading={loading}>
            {tokenVerified ? (
                <div className='min-h-screen h-full'>
                    <FaArrowCircleLeft
                        size={32}
                        onClick={handleGoBack}
                        className="cursor-pointer hover:opacity-80"
                    />

                    <div>
                        {/* Title Section */}
                        <div className='py-6'>
                            <CardHeader>
                                <CardTitle>Consulta</CardTitle>
                                <CardDescription>
                                    {queryConfig.title}
                                </CardDescription>
                            </CardHeader>
                        </div>

                        {/* Date Picker Section */}
                        {needsDateSearch && (
                            <div className="py-8">
                                <DatePicker
                                    date={date}
                                    setDate={setDate}
                                    maxDate={today}
                                />
                            </div>
                        )}

                        {/* Filters Section */}
                        {queryConfig.showCategory && (
                            <QueryFilters
                                category={category}
                                setCategory={setCategory}
                                priceRange={priceRange}
                                setPriceRange={setPriceRange}
                                provider={prov}
                                setProvider={setProv}
                                darkMode={darkMode}
                            />
                        )}

                        {/* Chart Modal */}
                        <ChartModal
                            isOpen={showModal && !loading}
                            setIsOpen={setShowModal}
                            chartData={chartData}
                            queryNumber={nConsulta}
                            chartRef={chartRef}
                            onChartFinished={handleChartFinished}
                        />
                    </div>

                    {/* Main Content Section */}
                    <div className="padding">
                        {/* Search Bar */}
                        {/* {!needsDateSearch && !queryConfig.showCategory && (
                            <div className="py-6">
                                <SearchBar
                                    search={search}
                                    setSearch={setSearch}
                                    setFetchSearch={setSearchItem}
                                />
                            </div>
                        )} */}

                        {/* Data Table */}
                        {data?.length > 0 ? (
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        {queryConfig.columns.map((column, index) => (
                                            <TableCell
                                                key={index}
                                                style={{
                                                    backgroundColor: darkMode ? "#422653" : "",
                                                    color: darkMode ? "white" : "",
                                                }}
                                            >
                                                {column.headerName}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {data.map((row, rowIndex) => (
                                        <React.Fragment key={`frag-${rowIndex}`}>
                                            <TableRow key={rowIndex}>
                                                {queryConfig.columns.map((column, colIndex) => (
                                                    <TableCell key={colIndex}>
                                                        {column.field === 'consultarItem' ? (
                                                            <FaSearch
                                                                className="cursor-pointer"
                                                                title="Consultar"
                                                                onClick={() => {
                                                                    handleSetShowRowIndex(rowIndex);
                                                                    setItemToFetch(row.sku_id   //for query 5 e 6
                                                                        || row.ingr_id          //for query 4
                                                                        || row.user);           //for query 8
                                                                }}
                                                            />
                                                        ) : column.field === 'sku_prod_name' ? (
                                                            <TableCell title={row["concatenated_field"]} key={colIndex}>
                                                                {row[column.field]}
                                                            </TableCell>
                                                        ) : (
                                                            row[column.field]
                                                        )}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                            <TableRow className='bg-amber-50 hover:bg-amber-50' key={`drill${rowIndex}`} style={{ display: showRowIndex === rowIndex ? 'table-row' : 'none' }}>
                                                <TableCell colSpan={4}>
                                                    <div className='flex gap-4'>
                                                        <button
                                                            onClick={() => setDrillToDisplay('drilldown')}
                                                            className={`rounded-lg ${drillToDisplay === 'drilldown' ? "bg-blue-200 hover:bg-blue-300 " : "bg-zinc-200 hover:bg-zinc-300 "} p-2 transition-all duration-300 ease-in-out`}>
                                                            Acessos
                                                        </button>
                                                        <button
                                                            onClick={() => setDrillToDisplay('drilldownFunc')}
                                                            className={`rounded-lg  ${drillToDisplay === 'drilldownFunc' ? "bg-blue-200 hover:bg-blue-300 " : "bg-zinc-200 hover:bg-zinc-300 "} p-2 transition-all duration-300 ease-in-out`}>
                                                            Funcionalidades acedidas
                                                        </button>
                                                    </div>
                                                </TableCell>
                                                <TableCell >
                                                    <button
                                                        onClick={() => handleSetShowRowIndex(rowIndex)}
                                                        className='rounded-lg bg-red-300 hover:bg-red-400 p-2 transition-all duration-300 ease-in-out'>
                                                        Fechar
                                                    </button>
                                                </TableCell>
                                            </TableRow>
                                            {drillToDisplay &&
                                                <TableRow className='bg-amber-50 hover:bg-amber-50' key={`drillTable${rowIndex}`} style={{ display: showRowIndex === rowIndex ? 'table-row' : 'none' }}>
                                                    <TableCell colSpan={5}>
                                                        {renderTableDrill(drillToDisplay)}
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        ) : (
                            !loading && searchItem && (
                                <div className={`text-center text-2xl mt-10 ${darkMode ? 'text-white' : 'text-black'
                                    }`}>
                                    <p>
                                        Sem {queryConfig.responseType} que contenham {searchItem} no nome
                                    </p>
                                </div>
                            )
                        )}

                        {/* Pagination */}
                        <div className="flex justify-center pt-20">
                            <Paginate
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                totalPages={totalPages}
                                loading={!data}
                            />
                        </div>
                    </div>

                    {/* Loading Overlay */}
                    <Loading isOpen={loading} />
                </div>
            ) : null}
        </Page>
    );
};

export default IndividualQuery;

