// React
import { useState } from "react";

// API
import { axiosPrivate } from "../../utils/api/axios";

// Components
import Toaster from "../../components/reusable/Toaster";
import {
  CardHeader,
  CardTitle,
  CardDescription,
} from "../../components/ui/card";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";

// Icons
import { Label } from "../../components/ui/label";
import { MdOutlinePassword } from "react-icons/md";
import { Card } from "../../components/ui/card";

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleChange = async () => {
    if (newPassword !== confirmPassword) {
      Toaster.show("Passwords não coincidem", "error");
      return;
    }

    const data = {
      current_password: oldPassword,
      new_password: newPassword,
      re_new_password: confirmPassword,
    };

    try {
      const response = await axiosPrivate.post(
        "set_password",
        JSON.stringify(data)
      );

      if (response.status === 200) {
        Toaster.show("Password alterada com sucesso!", "success");
      } else {
        Toaster.show(response.data.message, "error");
      }
    } catch (error) {
      console.error("Error:", error);
      Toaster.show(
        "Erro ao alterar password. " + error.response.data.message,
        "error"
      );
    }
  };

  return (
    <Card className="p-6">
      <CardHeader>
        <CardDescription>
          Preencha os campos abaixo para alterar a sua password
        </CardDescription>
      </CardHeader>
      <div className="py-5">
        <div className="flex flex-col">
          <Label className="text-sm font-medium">Password Atual</Label>
          <Input
            onChange={(e) => setOldPassword(e.target.value)}
            type="password"
            placeholder="Insira Password Atual"
          />
        </div>
        <div className="flex flex-col pt-5">
          <Label className="text-sm font-medium">Nova Password</Label>
          <Input
            onChange={(e) => setNewPassword(e.target.value)}
            type="password"
            placeholder="Insira Nova Password"
          />
        </div>
        <div className="flex flex-col pt-5">
          <Label className="text-sm font-medium">
            Confirme a nova Password
          </Label>
          <Input
            onChange={(e) => setConfirmPassword(e.target.value)}
            type="password"
            placeholder="Confirme a nova Password"
          />
        </div>
        <div className="flex justify-end">
          <Button className="mt-5" onClick={handleChange}>
            <MdOutlinePassword className="inline mx-2" /> Alterar Password
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default ChangePassword;
