// React
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// Hooks
import useTokenVerifier from "../../../../utils/hooks/useTokenVerifier";
import { useApi } from "../../../../utils/hooks/useAxiosPrivate";
import useDarkMode from "../../../Profile/usedarkmode";

// Components
import Page from "../../page";
import InfoComponent from "../../../../components/reusable/info/info.component";
import Loading from "../../../../components/reusable/loading/loading";
import Paginate from "../../../../components/reusable/pagination/paginate";
import Toaster from "../../../../components/reusable/Toaster";
import { Button } from "../../../../components/ui/button";
import { Label } from "../../../../components/ui/label";
import { useLoading } from "../../../../utils/contexts/LoadingContext";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../../../components/ui/table";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../../../components/ui/select";

// Icons
import { FaPlusCircle } from "react-icons/fa";
import { IoAdd } from "react-icons/io5";
import { MdAssignmentTurnedIn, MdHelpOutline } from "react-icons/md";
import { MdOutlineModeEditOutline } from "react-icons/md";
import Modal from "../../../../components/reusable/Modal";
import {
    CardDescription,
    CardTitle,
    CardHeader,
} from "../../../../components/ui/card";
import { Input } from "../../../../components/ui/input";
import ProviderForm from "./ProviderFrom";
// Styles
// (No styles imported in this file)

const Providers = () => {
    const [tokenVerified, setTokenVerified] = useState(false);
    const navigate = useNavigate();
    // Callback function to update token verification status
    const handleTokenVerified = () => {
        setTokenVerified(true);
    };
    useTokenVerifier({
        onTokenVerified: handleTokenVerified,
        log: "MENU INVOICES",
    });

    const { get, post } = useApi();
    const [providers, setProviders] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const { loading, setLoading } = useLoading();
    const [change, setChange] = useState(false);
    const [search, setSearch] = useState("");


    useEffect(() => {

        // Get providers from the backend
        const getProviders = async () => {
            setLoading(true);
            try {
                const response = await get("get_all_suppliers");
                setProviders(response.data);
                setLoading(false);

            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };
        getProviders();

    }, [change]);




    const darkMode = useDarkMode();

    return (
        <Page title={"Fornecedores"} loading={loading}>
                <div className="min-h-screen">
                    <div className="w-[90%] m-auto text-black">
                        {/* Header Section */}
                        <div className="flex items-center justify-between">
                            <CardHeader>
                                <CardTitle className="text-4xl font-semibold text-blue-dark">
                                    Fornecedores
                                </CardTitle>
                                <CardDescription>
                                    Consulte e edite as suas fornecedores.
                                </CardDescription>
                            </CardHeader>
                            <Button
                                onClick={() => {
                                    setShowModal(true);
                                }}
                            >
                                <IoAdd size={16} className="mr-2" /> Fornecedor
                            </Button>
                        </div>

                        {/* Info Section */}
                        <InfoComponent text="">
                            <div>
                                <p>
                                    Crie um fornecedor
                                </p>
                            </div>
                        </InfoComponent>
                        {/* <div className="py-4">
                            <Input
                                type="text"
                                name="search"
                                placeholder="Pesquisar"
                                className="w-80 h-10"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div> */}

                        {providers.length > 0 ? (
                            <div>
                                <Table>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHead>Nome</TableHead>
                                            <TableHead>Endereço</TableHead>
                                            <TableHead>País</TableHead>
                                            <TableHead>Email</TableHead>
                                            <TableHead>Contato</TableHead>
                                            <TableHead>NIPC</TableHead>
                                            <TableHead>Código Postal</TableHead>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody
                                        style={{ backgroundColor: darkMode ? "#262853" : "" }}
                                        className="bg-white divide-y divide-gray-200"
                                    >
                                        {providers.map((provider) => (
                                            <TableRow key={provider.suppl_id}>
                                                <TableCell>{provider.suppl_name}</TableCell>
                                                <TableCell>{provider.suppl_address}</TableCell>
                                                <TableCell>{provider.suppl_countrycode}</TableCell>
                                                <TableCell>{provider.suppl_maincontact_email}</TableCell>
                                                <TableCell>{provider.suppl_maincontact_name} - {provider.suppl_maincontact_phone}</TableCell>
                                                <TableCell>{provider.suppl_nipc}</TableCell>
                                                <TableCell>{provider.suppl_postal_code.join(', ')}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        ) : (
                            <div
                                style={{ color: darkMode ? "white" : "black" }}
                                className="text-center text-2xl mt-10"
                            >
                                Nao existem fornecedores criados. Crie um fornecedor.
                            </div>
                        )}
                    </div>
                    <Modal isOpen={showModal} setIsOpen={setShowModal}>
                        <ProviderForm setShowModal={setShowModal} setChange={setChange}/>
                    </Modal>
                    <Loading isOpen={loading} />
                </div>
        </Page>
    );
};

export default Providers;
