import { createContext, useContext } from "react";

export interface InterceptorsContextProps {
  interceptorsReady: boolean;
}

export const InterceptorsContext = createContext<InterceptorsContextProps>({
  interceptorsReady: false,
});

export const useInterceptorsContext = () => useContext(InterceptorsContext);
