import React from 'react';
import CategoryFormatter from "../../../../components/reusable/select/categories";
import SelectComponent from "../../../../components/reusable/select/select";
import RangeFormatter from "../../../../components/reusable/select/range";
import ProviderFormatter from "../../../../components/reusable/select/provider";

interface QueryFiltersProps {
  category: string;
  setCategory: (value: string) => void;
  priceRange: string;
  setPriceRange: (value: string) => void;
  provider: string;
  setProvider: (value: string) => void;
  darkMode: boolean;
}

export const QueryFilters: React.FC<QueryFiltersProps> = ({
  category,
  setCategory,
  priceRange,
  setPriceRange,
  provider,
  setProvider,
  darkMode
}) => (
  <div className="flex flex-col mil:flex-row justify-evenly pb-4 gap-4">
    <div className="flex justify-center">
      <SelectComponent
        formatter={CategoryFormatter}
        darkMode={darkMode}
        onChange={setCategory}
        value={category}
      />
    </div>
    <div className="flex justify-center">
      <SelectComponent
        formatter={RangeFormatter}
        darkMode={darkMode}
        onChange={setPriceRange}
        value={priceRange}
      />
    </div>
    <div className="flex justify-center">
      <SelectComponent
        formatter={ProviderFormatter}
        darkMode={darkMode}
        onChange={setProvider}
        value={provider}
      />
    </div>
  </div>
);