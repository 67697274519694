// React
import React, { useCallback, useEffect, useState } from "react";

// Hooks
import useTokenVerifier from "../../../../utils/hooks/useTokenVerifier";
import useDarkMode from "../../../Profile/usedarkmode";
import { useApi } from "../../../../utils/hooks/useAxiosPrivate";

// Components
import Page from "../../page";
import Loading from "../../../../components/reusable/loading/loading";
import InfoComponent from "../../../../components/reusable/info/info.component";
import Toaster from "../../../../components/reusable/Toaster";
import { Button } from "../../../../components/ui/button";
import {
  TableHeader,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "../../../../components/ui/table";
import { CardDescription, CardTitle } from "../../../../components/ui/card";
import { Label } from "../../../../components/ui/label";
import { useLoading } from "../../../../utils/contexts/LoadingContext";

// Icons
import { IoPricetag } from "react-icons/io5";
import { AiFillEdit } from "react-icons/ai";
import { TiTick } from "react-icons/ti";
import { FaExchangeAlt } from "react-icons/fa";
import Modal from "../../../../components/reusable/Modal";
import { DatePicker } from "../../../../components/ui/datePicker";

interface DataItem {
  dt_ref: string;
  sku_provider_id: string;
  sku_prov_name: string;
  total: number;
}

interface FillDataModalProps {
  isOpen: boolean;
  onClose: () => void;
  onFill: () => void;
  date: Date;
  setDate: (date: Date) => void;
  darkMode: boolean;
}

interface DataTableProps {
  data: DataItem[];
  darkMode: boolean;
  onEditClick: (item: DataItem) => void;
  setShowModal: (show: boolean) => void;
}

const DataFilling = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "DATA FILLING PAGE",
  });

  const { get, post } = useApi();
  const [data, setData] = useState<DataItem[]>([]);
  const { loading, setLoading } = useLoading();
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<DataItem | null>(null);
  const [date, setDate] = useState(null);
  const [missingData, setMissingData] = useState(false);
  const darkMode = useDarkMode();
  const today = new Date();
  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await get(
        `list_missing_data/?missing_data=${missingData}`
      );
      console.log(response.data);
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      Toaster.show("Erro ao carregar lista de dados", "error");
    } finally {
      setLoading(false);
    }
  }, [missingData]);

  const handleFill = async () => {
    if (!selectedItem || !date) {
      Toaster.show("Por favor, selecione uma data", "error");
      return;
    }

    try {
      const fillInfo = {
        ...selectedItem,
        dt_ref: convertDateFormat(selectedItem.dt_ref),
        date: convertDate(date),
      };
      await post("fill_data/", JSON.stringify(fillInfo));
      Toaster.show("Dados preenchidos com sucesso", "success");
      setShowModal(false);
      setDate("");
      fetchData();
    } catch (error) {
      console.error("Error filling data:", error);
      Toaster.show("Erro ao preencher dados", "error");
    }
  };

  const convertDateFormat = (dateStr: string): string =>
    dateStr.replace(/-/g, "");

  const convertDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    return `${year}${month}${day}`;
  };
  useEffect(() => {
    fetchData();
  }, [fetchData, missingData]);

  const DataTable: React.FC<DataTableProps> = ({
    data,
    darkMode,
    onEditClick,
    setShowModal,
  }) => {
    if (data.length === 0) {
      return null;
    }
    return (
      <Table className="min-w-full divide-y divide-gray-200 shadow-2xl rounded">
        <TableHeader>
          <TableRow>
            {[
              "Data de Referência",
              "Fornecedor",
              "Total de Resultados",
              "Preencher",
            ].map((header) => (
              <TableCell key={header}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{item.dt_ref}</TableCell>
              <TableCell>
                {`${item.sku_provider_id} - ${item.sku_prov_name}`}
              </TableCell>
              <TableCell>{item.total || 0}</TableCell>
              <TableCell>
                {!item.total ? (
                  <AiFillEdit
                    size={32}
                    className="cursor-pointer"
                    color="red"
                    title="Preencher"
                    onClick={() => {
                      setShowModal(true);
                      onEditClick(item);
                    }}
                  />
                ) : (
                  <TiTick size={32} color="green" title="Preenchido" />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  const FillDataModal: React.FC<FillDataModalProps> = ({
    isOpen,
    onClose,
    onFill,
    date,
    setDate,
    darkMode,
  }) => {
    return (
      <Modal isOpen={isOpen} setIsOpen={setShowModal}>
        <div className="overflow-auto">
          <h2
            style={{
              color: darkMode ? "white" : "black",
              paddingBottom: "20px",
              textAlign: "center",
            }}
          >
            Preencher dados
          </h2>
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-2">
              <Label
                className={`text-xl font-bold ${
                  darkMode ? "text-white" : "text-black"
                }`}
              >
                Data
              </Label>
              <Label
                className={`text-sm font-bold ${
                  darkMode ? "text-zinc-200" : "text-zinc-500"
                }`}
              >
                Data que será utilizada para preencher os dados em falta
              </Label>
              <DatePicker date={date} setDate={setDate} maxDate={today} />
              {/* <input
                type="date"
                value={date}
                style={{
                  color: darkMode ? "white" : "black",
                  backgroundColor: darkMode ? "#422653d4" : "#d9d9d9",
                }}
                onChange={(e) => setDate(e.target.value)}
                className="w-full px-4 py-2 border-none bg-zinc-200 text-black rounded-[25px] outline-zinc-300"
              /> */}
            </div>
            <div className="flex justify-between gap-x-3 pt-5">
              <Button
                onClick={onFill}
                className="bg-indigo-500 hover:bg-indigo-600"
              >
                Preencher
              </Button>
              <Button onClick={onClose} className="bg-red-500 hover:bg-red-600">
                Cancelar
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <Page title={"Preenchimento da Dados"} loading={loading}>
      {tokenVerified ? (
        <div className="w-[90%] mx-auto">
          <div className="flex flex-col ">
            <div>
              <CardTitle>Data Filling</CardTitle>
              <CardDescription>Preencha os dados em falta</CardDescription>
            </div>
            <div className="flex gap-4 items-center py-6">
              <div className="flex relative bg-zinc-200 w-fit rounded-full">
                <div
                  className={`rounded-full px-4 py-2 w-40 flex justify-center h-9 `}
                  onClick={() => setMissingData(false)}
                >
                  <p
                    className={`z-[2] text-sm transition-colors duration-500 ease-in-out  ${
                      !missingData ? "text-gray-200" : "text-gray-800"
                    }`}
                  >
                    Lista Completa
                  </p>
                </div>
                <div
                  className={`rounded-full px-4 py-2 w-40 flex justify-center h-9 `}
                  onClick={() => setMissingData(true)}
                >
                  <p
                    className={`z-[2] text-sm transition-colors duration-500 ease-in-out ${
                      missingData ? "text-gray-200" : "text-gray-800"
                    }`}
                  >
                    Dados em Falta
                  </p>
                </div>
                <div
                  className={`absolute bg-gray-900 top-0 w-40 h-9 rounded-full z-[1] transition-all duration-500 ease-in-out ${
                    !missingData ? "translate-x-0" : "translate-x-[100%]"
                  }`}
                ></div>
              </div>
            </div>
          </div>
          {data.length > 0 && (
            <div>
              <InfoComponent>
                <div>
                  <ul>
                    <li>
                      <strong>Lista Completa: </strong>Mostrar lista completa
                      independentemente de se existiu falha no scrapper
                    </li>
                    <li>
                      <strong>Dados em Falta: </strong>Mostrar apenas os casos
                      em que o scrapper falhou, em que não existem dados
                    </li>
                  </ul>
                  <br />
                  <p>
                    {missingData
                      ? "Dados em falta no último ano (limite de 20)"
                      : "Dados para os últimos 15 dias"}
                  </p>
                  <p>Falta de dados apresenta zero resultados</p>
                </div>
              </InfoComponent>
              <DataTable
                data={data}
                darkMode={darkMode}
                onEditClick={setSelectedItem}
                setShowModal={setShowModal}
              />
            </div>
          )}
          <FillDataModal
            isOpen={showModal}
            onClose={() => setShowModal(false)}
            onFill={handleFill}
            date={date}
            setDate={setDate}
            darkMode={darkMode}
          />
          <Loading isOpen={loading} />
        </div>
      ) : (
        // <Loading isOpen={true} />
        <></>
      )}
    </Page>
  );
};
export default DataFilling;
