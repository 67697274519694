import React from 'react';
import Modal from "../../../../components/reusable/Modal";
import ChartComponent from "../../../../components/reusable/charts/chartComponent";

interface ChartModalProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  chartData: any;
  queryNumber: number;
  chartRef: any;
  onChartFinished: () => void;
}

export const ChartModal: React.FC<ChartModalProps> = ({
  isOpen,
  setIsOpen,
  chartData,
  queryNumber,
  chartRef,
  onChartFinished
}) => (
  <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
    {queryNumber === 4 ? (
      <>
        <div className="py-4 flex justify-center w-full">
          <p className="text-xl">
            Evolução de preços referente aos últimos 30 dias
          </p>
        </div>
        <div className="grid xl:grid-cols-2">
          <ChartComponent
            chartRef={chartRef}
            option={chartData[0]}
            handleChartFinished={onChartFinished}
            ChangeScale={false}
          />
          <ChartComponent
            chartRef={chartRef}
            option={chartData[1]}
            handleChartFinished={onChartFinished}
            ChangeScale={false}
          />
        </div>
      </>
    ) : (
      <div className="scrollbar h-full overflow-auto">
        <ChartComponent
          chartRef={chartRef}
          option={chartData}
          handleChartFinished={onChartFinished}
        />
      </div>
    )}
  </Modal>
);