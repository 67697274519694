// React
import { useEffect, useState } from "react";

// Hooks
import useDarkMode from "../../../Profile/usedarkmode";
import useAxiosPrivate from "../../../../utils/hooks/useAxiosPrivate";
import { useClass } from "../../../../utils/contexts/ClassContext";

// Components
import InfoComponent from "../../../../components/reusable/info/info.component";
import SelectComponent from "../../../../components/reusable/select/select";
import ClasseFormatter from "../../../../components/reusable/select/classes";
import RecipeStatus from "../../../../components/reusable/select/recipeStatus";
import RecipeUsage from "../../../../components/reusable/select/recipeUsage";
import Toaster from "../../../../components/reusable/Toaster";
import { Button } from "../../../../components/ui/button";
import { Label } from "../../../../components/ui/label";
import { useLoading } from "../../../../utils/contexts/LoadingContext";
import { Input } from "../../../../components/ui/input";
import { Textarea } from "../../../../components/ui/textarea";

const recipeTypeFormatter = {
  getAll: () => ["Receita Simples", "Receita Complexa"],
  format: (value) => (value),
  default: "Receita Simples",
};

const RecipeForm = ({ recipe_id = 0, modalCloser, change, setChange, recipe = null }) => {
  const { Classes } = useClass();

  const axiosPrivate = useAxiosPrivate();
  const [recipeName, setRecipeName] = useState("");
  const [recipeDescription, setRecipeDescription] = useState("");
  const [recipeClass, setRecipeClass] = useState("");
  const [recipeIntructions, setRecipeIntructions] = useState("");
  const [recipeComments, setRecipeComments] = useState("");
  const [recipeRecursive, setRecipeRecursive] = useState("Receita Simples");
  const [numberDoses, setNumberDoses] = useState(1);
  const [order, setOrder] = useState(0);
  const [recipeStatus, setRecipeStatus] = useState("");
  const [recipeUsage, setRecipeUsage] = useState("I");
  const { setLoading } = useLoading();

  useEffect(() => {
    if (Classes.length > 0) {
      setRecipeClass(Classes[0].prod_category);
    }
  }, [Classes]);



  useEffect(() => {
    console.log("useEffect called with recipe:", recipe);
    console.log("recipe_id: ", recipe_id);
    console.log("modalCloser: ", modalCloser);
    console.log("change: ", change);
    console.log("setChange: ", setChange);
    if (recipe) {
      console.log("Recipe: ", recipe);
      // Edit recipe
      // const getRecipe = async (endpoint, params = {}) => {
      //   setLoading(true);
      //   const url = params
      //     ? `${endpoint}?${Object.entries(params)
      //       .map(([key, value]) => `${key}=${value}`)
      //       .join("&")}`
      //     : `${endpoint}`;
      //   try {
      //     const response = await axiosPrivate.get(url);
      //     console.log("Response: ", response);

          setRecipeName(recipe.recipe_name);
          setRecipeDescription(recipe.recipe_descrip);
          setRecipeClass(recipe.recipe_class);
          setRecipeIntructions(recipe.recipe_intructions);
          setRecipeComments(recipe.recipe_comments);
          setRecipeRecursive(recipe.recipe_recursive);
          setNumberDoses(recipe.recipe_num_doses);
          setOrder(recipe.recipe_order);
          setRecipeStatus(recipe.recipe_status);
          setRecipeUsage(recipe.recipe_usage);
    //     } catch (error) {
    //       Toaster.show("Erro ao buscar receita.", "error");
    //       setLoading(false);
    //     }
    //   };
    //   getRecipe("assign_list", { recipe_id: recipe_id });
    // }}
    }
  }, [recipe]); // Include recipe_id in the dependency array to trigger useEffect on its change

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (
      recipeName === "" ||
      recipeClass === "" ||
      recipeDescription === "" ||
      recipeRecursive === ""
    ) {
      modalCloser(false);
      if (isNaN(numberDoses)) {
        Toaster.show("Número de doses deve ser um número.", "error");
        return;
      }
      Toaster.show(
        "Por favor, preencha os campos de nome, doses, classe, descrição e tipo.",
        "error"
      );
      return;
    }
    if (numberDoses < 1) {
      Toaster.show("Número de doses deve ser igual ou superior a 1.", "error");
      return;
    }
    setLoading(true);

    const data = {
      recipe_id: recipe_id,
      recipe_name: recipeName,
      recipe_descrip: recipeDescription,
      recipe_class: recipeClass,
      recipe_intructions: recipeIntructions,
      recipe_comments: recipeComments,
      recipe_recursive: recipeRecursive === "Receita Simples" ? "s" : "c",
      recipe_num_doses: numberDoses,
      recipe_order: recipe_id === 0 ? -1 : recipeRecursive === "s" ? 0 : order,
      recipe_status: recipe_id === 0 ? "INCOMPLETE" : recipeStatus,
      recipe_usage: recipeUsage,
      //ao criar uma nova receita, o order é -1 e ao editar uma receita, passar para simples order fica 0 e para complexa fica o valor do order
    };
    try {
      const response = await axiosPrivate.post(
        `/recipe_create_or_update/`,
        JSON.stringify(data)
      );

      if (response.status === 200) {
        Toaster.show("Receita guardada com sucesso.", "success");
        setChange(!change);
        modalCloser(false)
      } else {
        Toaster.show("Erro ao guardar receita.", "error");
        setLoading(false);
        modalCloser(false)
      }
    } catch (error) {
      Toaster.show("Erro ao guardar receita.", "error");
      setLoading(false);
    }
  };
  const darkMode = useDarkMode();

  return (
    <div className="max-h-[50vh]">
      <div className="flex items-center justify-center ">
        <div >
          <div className="mb-4 flex items-center">
            <div className="w-1/2 pr-2">
              <Label
                style={{ color: darkMode ? "white" : "black" }}
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Nome
              </Label>
              <Input
                name="Nome da Receita"
                type="text"
                value={recipeName}
                onChange={(e) => setRecipeName(e.target.value)}
              />
            </div>
            <div className="w-1/2 pl-2">
              <Label
                style={{ color: darkMode ? "white" : "black" }}
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Classe
              </Label>
              <SelectComponent
                formatter={ClasseFormatter}
                darkMode={darkMode}
                onChange={setRecipeClass}
                value={recipeClass}
              /> 
            </div>
          </div>

          <InfoComponent text="">
            <p>
              Defina a quantidade de porções ou refeições que a receita pode
              produzir.
            </p>
          </InfoComponent>

          <div className="mb-4">
            <Label
              style={{ color: darkMode ? "white" : "black" }}
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Número de Doses
            </Label>
            <Input
              name="Número de Doses"
              type="number"
              min={1}
              value={numberDoses}
              onChange={(e) => setNumberDoses(Number(e.target.value))}
            />
          </div>

          <div className="mb-4">
            <Label
              style={{ color: darkMode ? "white" : "black" }}
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Descrição
            </Label>
            <Textarea
              value={recipeDescription}
              onChange={(e) => setRecipeDescription(e.target.value)}
              maxLength={255}
            />
          </div>

          <div className="mb-4">
            <Label
              style={{ color: darkMode ? "white" : "black" }}
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Instruções
            </Label>
            <Textarea
              value={recipeIntructions}
              onChange={(e) => setRecipeIntructions(e.target.value)}
              maxLength={255}
            />
          </div>

          <div className="mb-4">
            <Label
              style={{ color: darkMode ? "white" : "black" }}
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Comentário
            </Label>
            <Textarea
              value={recipeComments}
              onChange={(e) => setRecipeComments(e.target.value)}
              maxLength={255}
            />
          </div>

          {recipe_id !== 0 && (
            <div className="mb-4">
              <Label
                style={{ color: darkMode ? "white" : "black" }}
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Estado
              </Label>
              <SelectComponent
                formatter={RecipeStatus}
                darkMode={darkMode}
                onChange={setRecipeStatus}
                value={recipeStatus}
              />
            </div>
          )}



          <div className="mb-4">
            <Label
              style={{ color: darkMode ? "white" : "black" }}
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Utilização da Receita
            </Label>
            <InfoComponent text="">
              <div className="flex flex-col gap-2">
                <p>
                  <strong>Receitas Menu(M)</strong> - Produtos vendidos pelo restaurante
                  confeccionados internamente.
                </p>
                <p>
                  <strong>Receitas Internas(I)</strong> - Componentes ou ingredientes que sejam
                  usados em receitas (um molho bechamel, por exemplo) ou que sejam
                  usados em porções como parte de um prato desde que sejam
                  confeccionados internamente e portanto tenham uma receita (mas
                  que não sejam vendidos directamente mas sim incorporados nos
                  produtos vendidos pelo restaurante).
                </p>
                <p>
                  <strong>Outras Receitas(O)</strong> - Produtos que o Restaurante tem interesse em
                  monitorizar o respectivo custo por diversas razões (pratos que
                  podem vir a fazer parte do menu ou já fizeram e podem voltar a
                  fazer, etc.)
                </p>
              </div>
            </InfoComponent>
            <SelectComponent
              formatter={RecipeUsage}
              darkMode={darkMode}
              onChange={setRecipeUsage}
              value={recipeUsage}
            />
          </div>

          <div className="mb-4">
            <Label
              style={{ color: darkMode ? "white" : "black" }}
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Tipo
            </Label>
            <InfoComponent text="">
              <p>
                Uma receita simples é constituída apenas por ingredientes. Uma
                receita complexa tem, na sua composição, uma ou mais
                sub-receitas.
              </p>
            </InfoComponent>
            <SelectComponent
              formatter={recipeTypeFormatter}
              darkMode={darkMode}
              onChange={setRecipeRecursive}
              value={recipeRecursive}
            />

          </div>
          <div className="flex gap-6 w-full justify-end">
            <Button
              onClick={() => modalCloser(false)}
              type="submit"
              className="py-4 my-4 bg-red-400 hover:bg-red-500"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              type="submit"
              className="py-4 my-4"
            >
              Guardar receita
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipeForm;
