import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { axiosPrivate } from "../api/axios";
import useRefreshToken from "../hooks/useRefreshToken";
import useAuth from "../hooks/useAuth";
import {
  InterceptorsContext,
  InterceptorsContextProps,
} from "./InterceptorsContext";

interface AxiosInterceptorProviderProps {
  children: React.ReactNode;
}

export const AxiosInterceptorProvider: React.FC<AxiosInterceptorProviderProps> = ({
  children,
}) => {
  const refresh = useRefreshToken();
  const navigate = useNavigate();
  const { auth } = useAuth();

  const [interceptorsReady, setInterceptorsReady] = useState(false);

  useEffect(() => {
    //console.log("Attaching interceptors at top level...");

    // -- REQUEST INTERCEPTOR --
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${auth?.accessToken}`;
        }
        //console.log("Global Request Interceptor => ", config);
        return config;
      },
      (error) => Promise.reject(error)
    );

    // -- RESPONSE INTERCEPTOR --
    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        //console.log("Interceptor error object =>", error);
        const prevRequest = error?.config;
        if (error?.response?.status === 401 && !prevRequest?.sent) {
          prevRequest.sent = true;
          try {
            const newAccessToken = await refresh();
            if (prevRequest.url === "/auth/jwt/verify") {
              prevRequest.data = { token: newAccessToken };
            }
            prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
            return axiosPrivate(prevRequest);
          } catch (refreshError) {
            //console.error("Error refreshing token (Global Interceptor): ", refreshError);
            navigate("/");
            return Promise.reject(refreshError);
          }
        }
        return Promise.reject(error);
      }
    );

    // Once attached, set interceptorsReady to true
    setInterceptorsReady(true);

    // Optionally eject if you want to remove them on unmount
    // return () => {
    //   axiosPrivate.interceptors.request.eject(requestIntercept);
    //   axiosPrivate.interceptors.response.eject(responseIntercept);
    // };
  }, [refresh]);

  // Provide interceptorsReady to children
  const ctxValue: InterceptorsContextProps = { interceptorsReady };

  return (
    <InterceptorsContext.Provider value={ctxValue}>
      {children}
    </InterceptorsContext.Provider>
  );
};
