// React
import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

// Hooks
import useTokenVerifier from "../../../../utils/hooks/useTokenVerifier";
import useAxiosPrivate from "../../../../utils/hooks/useAxiosPrivate";
import useDarkMode from "../../../Profile/usedarkmode";
import { useLoading } from "../../../../utils/contexts/LoadingContext";

// Components
import Page from "../../page";
import IngredCreateModal from "../ingredients/IngredCreateModal";
import IngredEditModal from "../ingredients/IngredEditModal";
import BackComponent from "../../../../components/reusable/goBack/go-back-component";
import InfoComponent from "../../../../components/reusable/info/info.component";
import Loading from "../../../../components/reusable/loading/loading";
import Toaster from "../../../../components/reusable/Toaster";
import { Button } from "../../../../components/ui/button";
// Icons
import { FaTrash } from "react-icons/fa";
import { IoIosLink, IoMdAddCircle } from "react-icons/io";
import { MdOutlineModeEditOutline } from "react-icons/md";
import Modal from "../../../../components/reusable/Modal";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";

// Styles
// (No styles imported in this selection)

const RecipeDetailPage = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "MENU INGREDIENTS PAGE",
  });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const recipeId = queryParams.get("id");
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const { loading, setLoading } = useLoading();
  const [modalCreate, setModalVisibleCreate] = useState(false);
  const [modalEdit, setModalVisibleEdit] = useState(false);
  const [editable, setEditable] = useState(0);
  const [assignId, setAssignID] = useState(0);
  const [changedList, setChangedList] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(-1);
  const [quantity, setQuantity] = useState(0);
  const [quantityType, setQuantityType] = useState("gr");

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("pt-PT", {
      hour: "numeric",
      minute: "numeric",
    });
    return formattedDate;
  }

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getGraph = async (endpoint, params = {}) => {
      setLoading(true);
      const url = params
        ? `${endpoint}?${Object.entries(params)
            .map(([key, value]) => `${key}=${value}`)
            .join("&")}`
        : `${endpoint}`;

      try {
        const response = await axiosPrivate.get(url, {
          signal: controller.signal,
        });

        setData(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    getGraph("assign_list", { recipe_id: recipeId });
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [changedList]);

  const deleteIngred = async (ingr_id: Number) => {
    setLoading(true);
    const body = {
      ingr_id: ingr_id,
      recipe_id: recipeId,
    };
    const response = await axiosPrivate.post(
      `/assign_delete/`,
      JSON.stringify(body)
    );
    if (response.status === 200) {
      Toaster.show("Ingrediente apagado com sucesso.", "success");
      setLoading(false);
      setChangedList(!changedList);
    } else {
      setLoading(false);
      Toaster.show("Erro ao apagar ingrediente.", "error");
    }
  };
  const darkMode = useDarkMode();

  return (
    <Page title={"Ingredientes"} loading={loading}>
      {tokenVerified ? (
        <div>
          {data && (
            <div className="w-[90%] m-auto text-black min-h-screen">
              <BackComponent></BackComponent>
              <div className="flex items-center mt-10 justify-between gap-4">
                <p
                  style={{ color: darkMode ? "white" : "black" }}
                  className="text-4xl font-semibold text-blue-dark"
                >
                  {data["recipes"][0].recipe_name}{" "}
                  {data["recipes"][0].recipe_class}
                </p>
                <Button onClick={() => setModalVisibleCreate(true)}>
                  + Associar Ingrediente
                </Button>
              </div>

              <InfoComponent text="">
                <p>
                  Lista de ingredientes associados à receita{" "}
                  {data["recipes"][0].recipe_name}. Receita criada a{" "}
                  {formatDate(data["recipes"][0].ztimestamp)}.
                </p>
              </InfoComponent>
              <Table className="min-w-full divide-y divide-gray-200 shadow-2xl rounded">
                <TableHeader className="bg-white">
                  <TableRow>
                    <TableHead>Nome</TableHead>
                    <TableHead>Classe</TableHead>
                    <TableHead>Quantidade</TableHead>
                    <TableHead></TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {data && data["ingreds"].length > 0 ? (
                    data["ingreds"].map((item: any, index: any) => (
                      <TableRow key={index}>
                        <TableCell>{item.ingr_name}</TableCell>
                        <TableCell>{item.ingr_category}</TableCell>
                        <TableCell>
                          {data["qnt"][index].ingr_qt}{" "}
                          {data["qnt"][index].ingr_qt_unit}
                        </TableCell>
                        <TableCell>
                          <div className="flex space-x-4">
                            <IoMdAddCircle
                              title="Associar SKU"
                              className="cursor-pointer text-xl hover:scale-125 transition-transform duration-30"
                              style={{ color: darkMode ? "white" : "black" }}
                              onClick={() =>
                                navigate(
                                  `/app/menu/ingredient?id=${item.ingr_id}`,
                                  {
                                    state: {
                                      id: item.ingr_id,
                                      name: item.ingr_name,
                                    },
                                  }
                                )
                              }
                            />
                            <MdOutlineModeEditOutline
                              className="hover:scale-125 transition-transform duration-30 text-gray-600 hover:text-gray-800 text-xl cursor-pointer bg-transparent"
                              title="Editar"
                              onClick={() => {
                                setModalVisibleEdit(true);
                                setEditable(item);
                                setAssignID(data["qnt"][index].assign_ingr_id);
                                setQuantity(data["qnt"][index].ingr_qt);
                                setQuantityType(
                                  data["qnt"][index].ingr_qt_unit
                                );
                              }}
                            />

                            <FaTrash
                              onClick={() =>
                                Toaster.showWithHandler(
                                  `Estará a eliminar o item ${item.ingr_name}. Deseja continuar?`,
                                  () => deleteIngred(item.ingr_id),
                                  { duration: Infinity }
                                )
                              }
                              title="Eliminar"
                              className="text-red-300 hover:text-red-500 text-xl cursor-pointer hover:scale-125 transition-transform duration-30"
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} className="text-sm">
                        Não existem ingredientes associados a esta receita.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          )}
          <Modal isOpen={modalCreate} setIsOpen={setModalVisibleCreate}>
            <div style={{ overflowY: "auto", overflowX: "hidden" }}>
              {data && (
                <IngredCreateModal
                  modalCloser={setModalVisibleCreate}
                  setChange={setChangedList}
                  change={changedList}
                  recipeName={data["recipes"][0].recipe_name}
                />
              )}
            </div>
          </Modal>
          <Modal isOpen={modalEdit} setIsOpen={setModalVisibleEdit}>
            <div
              style={{
                padding: "50px 20px",
                height: "100%",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <p
                style={{ color: darkMode ? "white" : "black" }}
                className="text-4xl font-semibold text-blue-dark"
              >
                <p style={{ textAlign: "center" }}>
                  Edite a quantidade ou unidade do ingrediente
                </p>
              </p>
              <IngredEditModal
                qntType={quantityType}
                quantity={quantity}
                assignId={assignId}
                ingred={editable}
                modalCloser={setModalVisibleEdit}
                setChange={setChangedList}
                change={changedList}
              />
            </div>
          </Modal>
          <Loading isOpen={loading} />
        </div>
      ) : (
        // <Loading isOpen={true} />
        <></>
      )}
    </Page>
  );
};

export default RecipeDetailPage;
