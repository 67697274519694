import useDarkMode from "../../../pages/Profile/usedarkmode";
import { IoInformation } from "react-icons/io5";

const InfoComponent = ({
  text = null,
  children = <p>Texto de ajuda...</p>,
}) => {
  const darkMode = useDarkMode();

  if (!darkMode)
    return (
      <div>
        <div
          className="w-full flex items-center space-x-4 p-4 my-2 rtl:space-x-reverse text-gray-500 bg-white divide-x rtl:divide-x-reverse divide-gray-400 rounded-lg border border-gray-200"
          role="alert"
        >
          <div >
            <IoInformation size={32} className="text-2xl text-gray-500" />
          </div>
          <div className="ps-4 text-sm font-normal">{children}</div>
        </div>
      </div>
    );
  else
    return (
      <div
        className="w-full my-3 flex items-center space-x-4 p-4 rtl:space-x-reverse text-gray-200 bg-blue-dark divide-x rtl:divide-x-reverse divide-gray-600 rounded-lg shadow0"
        role="alert"
      >
        <IoInformation size={32} className="text-2xl text-gray-500" />
        <div className="ps-4 text-sm font-normal">{children}</div>
      </div>
    );
};

export default InfoComponent;
