import React from "react";
import { useNavigate } from "react-router-dom";
import useDarkMode from "../../../pages/Profile/usedarkmode";
import { Button } from "../../../components/ui/button";

const BackComponent = () => {
  const darkMode = useDarkMode();
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  return (
    <div className="py-4">
      <Button variant="outline" onClick={goBack}>
        Voltar
      </Button>
    </div>
  );
};

export default BackComponent;
