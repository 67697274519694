
const CardComponent = ({
  title,
  value,
  variation,
  showVariation = true,
  color = "255, 255, 255",
}: {
  title: string;
  value: React.ReactNode;
  variation?: number;
  showVariation?: boolean;
  color?: string;
}) => {

  return (
    <div
      style={{ backgroundColor: color !== "255, 255, 255" ? `rgba(${color}, 0.25)` : "rgba(255, 255, 255, 1)" }}
      className={`p-5 ${color} rounded-md mb-2`}
    >
      <div
        className={`text-base ${color !== "255, 255, 255" ? "text-zinc-700" : " text-gray-400"
          } `}
      >
        {title}
      </div>
      <div className="flex items-center pt-1">
        <div
          className={`text-2xl font-bold text-gray-900
            } `}
        >
          {value}
        </div>
        {showVariation && (
          <span
            className={`flex items-center px-3 py-0.5 mx-2 text-sm rounded-full ${variation >= 0
                ? "text-green-600 bg-green-100"
                : "text-red-600 bg-red-100"
              } float-right ml-auto`}
          >
            <span>{variation}%</span>
          </span>
        )}
      </div>
    </div>
  );
};

export default CardComponent;
