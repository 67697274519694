// React
import { useEffect, useState } from "react";

// Hooks
import useAxiosPrivate from "../../../../utils/hooks/useAxiosPrivate";
import useDarkMode from "../../../Profile/usedarkmode";

// Components
import InfoComponent from "../../../../components/reusable/info/info.component";
import Paginate from "../../../../components/reusable/pagination/paginate";
import SelectComponent from "../../../../components/reusable/select/select";
import CategoryFormatter from "../../../../components/reusable/select/categories";
import RangeFormatter from "../../../../components/reusable/select/range";
import ProviderFormatter from "../../../../components/reusable/select/provider";
import Toaster from "../../../../components/reusable/Toaster";
import { Button } from "../../../../components/ui/button";
import { Input } from "../../../../components/ui/input";
import { useLoading } from "../../../../utils/contexts/LoadingContext";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
import {
  CardHeader,
  CardTitle,
  CardDescription,
} from "../../../../components/ui/card";

// Icons
import { IoSearchCircleOutline } from "react-icons/io5";

// Other
import toast from "react-hot-toast";
import { IoIosSearch } from "react-icons/io";

const SkuCreate = ({ ingrName, ingrId, modalCloser, setChange, change }) => {
  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState(ingrName);
  const [searchItem, setSearchItem] = useState(true);
  const [selectedSkus, setSelectedSkus] = useState([]);
  const { setLoading } = useLoading();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [category, setCategory] = useState("Bebidas");
  const [priceRange, setPriceRange] = useState("<= 1€");
  const [prov, setProv] = useState("Pingo Doce");
  const darkMode = useDarkMode();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getSkuList = async (search: string) => {
      setLoading(true);
      const categoryRange = RangeFormatter.rangeToCategory(priceRange);
      const url = `sku_list_create?search=${search}&category=${category}&price_range=${categoryRange}&provider=${prov}&page=${currentPage}`;

      try {
        const response = await axiosPrivate.get(url);
        console.log(response.data);
        setData(response.data.data);
        setTotalPages(response.data.total_pages);

        if (!response.data.message || response.data.data.length === 0) {
          toast(
            "A sua pesquisa não retornou resultados. Mude os filtros e tente novamente.",
            {
              icon: (
                <IoSearchCircleOutline
                  style={{ color: darkMode ? "white" : "black" }}
                  size={32}
                />
              ),
            }
          );
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    getSkuList(search);

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [searchItem, currentPage]);

  const handleCheckboxChange = (sku_id) => {
    setSelectedSkus((prevSelectedSkus) => {
      if (prevSelectedSkus.find((item) => item.id === sku_id)) {
        return prevSelectedSkus.filter((item) => item.id !== sku_id);
      } else {
        return [...prevSelectedSkus, { id: sku_id, quantity: 0 }];
      }
    });
  };

  const handleAssign = async () => {
    setLoading(true);
    if (selectedSkus.length === 0) {
      Toaster.show("Selecione pelo menos um sku", "error");
      setLoading(false);
      return;
    }
    for (const sku of selectedSkus) {
      const skuToAssign = {
        assign_sku_id: 0,
        sku: sku.id,
        ingr: ingrId,
        ass_state: "ACTIVE",
      };
      try {
        const response = await axiosPrivate.post(
          `/sku_assign_create_or_update/`,
          JSON.stringify(skuToAssign)
        );

        if (response.status === 200 || response.status === 201) {
          Toaster.show("SKU associado com sucesso.", "success");
        } else {
          Toaster.show(`Erro ao associar SKU`, "error");
          setLoading(false);
          break;
        }
      } catch (error) {
        console.error("Error:", error);
        Toaster.show("Erro ao associar SKU.", "error");
        setLoading(false);
      }
    }
    setChange(!change);
  };

  return (
    <div>
      <CardHeader>
        <CardTitle>Associar SKUs</CardTitle>
        <CardDescription>
          Associe produtos do mercado à seu ingrediente.
        </CardDescription>
      </CardHeader>
      <div className="py-4">
        <InfoComponent text="">
          <p>Pesquise e associe produtos do mercado ao seu ingrediente.</p>
        </InfoComponent>
      </div>
      <div className="flex flex-col gap-4">
        <form onSubmit={(e) => { e.preventDefault(); setSearchItem(!searchItem); }} className="flex justify-between flex-wrap gap-2">
          <Input
            type="text"
            className="w-[300px] h-10"
            name="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Button type="submit" onClick={() => setSearchItem(!searchItem)}>
            <IoIosSearch className="text-white" size={25} />
          </Button>
        </form>
        <div className="flex flex-wrap gap-2 justify-between py-4">
          <SelectComponent
            formatter={CategoryFormatter}
            darkMode={darkMode}
            onChange={setCategory}
            value={category}
          />
          <SelectComponent
            formatter={RangeFormatter}
            darkMode={darkMode}
            onChange={setPriceRange}
            value={priceRange}
          />
          <SelectComponent
            formatter={ProviderFormatter}
            darkMode={darkMode}
            onChange={setProv}
            value={prov}
          />
        </div>
      </div>

      {data && data.length > 0 && (
        <>
          <Table className="mt-2 border border-gray-200">
            <TableHeader>
              <TableRow>
                <TableHead>Nome</TableHead>
                <TableHead>Preço</TableHead>
                <TableHead>Fornecedor</TableHead>
                <TableHead></TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {data.map((sku) => (
                <TableRow key={sku.sku_id}>
                  <TableCell title={sku.concatenated_field}>{sku.sku_prod_name}</TableCell>
                  <TableCell>{sku.last_sku_price}€</TableCell>
                  <TableCell>{sku.sku_prov_name}</TableCell>
                  <TableCell>
                    <input
                      type="checkbox"
                      checked={
                        selectedSkus.find((item) => item.id === sku.sku_id) !==
                        undefined
                      }
                      onChange={() => handleCheckboxChange(sku.sku_id)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div className="flex justify-center py-4">
            <Paginate
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
              loading={!data}
            />
          </div>
        </>
      )}
      <div className="w-full flex justify-end mt-2">
        <Button
          type="submit"
          onClick={() => {
            modalCloser(false);
            handleAssign();
          }}
        >
          Guardar Associação
        </Button>
      </div>
    </div>
  );
};

export default SkuCreate;
