import { Navigate, Outlet, useLocation  } from "react-router-dom";
import { useUser } from "./UserContext";
import Loading from "../../components/reusable/loading/loading";

export default function BackofficeRoutes() {
  const { groups, loading } = useUser() || {};
  const location = useLocation();

  // If your user data is still loading, show a spinner or placeholder
  if (loading) {
    return <Loading isOpen={loading} />;
}

  // If groups is null or does not include 'backoffice', redirect away
  if (!groups || !groups.includes("backoffice")) {
    // Pass the current location in state
    return <Navigate to="/app/homepage" replace state={{ from: location }} />;
  }

  // Otherwise, user is in backoffice => render the nested route
  return <Outlet />;
}
