import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useUser } from "./UserContext";
import Loading from "../../components/reusable/loading/loading";

export default function ProtectedRoutes() {
    const { user, profile, restaurant, activeSubscription, loading } = useUser();
    const location = useLocation();

    // 1) If still loading user, don't decide anything yet.
    if (loading) {
        // Return a loading spinner or empty fragment
        //console.log("Loading user data...");
        return <Loading isOpen={loading} />;
    }

    // 2) If user is not logged in at all, go to login
    if (!user) {
        //console.log("User not logged in, redirecting to login...");
        return <Navigate to="/" replace />;
    }

    // 3) Check if user or restaurant is in "NEW" status
    if (profile?.setup === "NEW" || restaurant?.setup === "NEW") {
        if (location.pathname !== "/user-setup") {
            console.log("User or restaurant setup is incomplete, redirecting to user setup...");
            return <Navigate to="/user-setup" replace />;
        }
    }

    // 4) Check subscription
    if (!activeSubscription && profile?.subscription !== "FREE") {
        if (location.pathname !== "/payments") {
            console.log("User has no active subscription, redirecting to payments...");
            return <Navigate to="/payments" replace />;
        }
    }
      
    // 5) Otherwise, user is all set
    return <Outlet />;
}
