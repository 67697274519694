// React
import { useEffect, useRef, useState } from "react";

// Hooks
import useTokenVerifier from "../../../../utils/hooks/useTokenVerifier";
import useAxiosPrivate from "../../../../utils/hooks/useAxiosPrivate";
import useDarkMode from "../../../Profile/usedarkmode";

// Components
import Page from "../../page";
import InfoComponent from "../../../../components/reusable/info/info.component";
import Loading from "../../../../components/reusable/loading/loading";
import Paginate from "../../../../components/reusable/pagination/paginate";
import Toaster from "../../../../components/reusable/Toaster";
import { Button } from "../../../../components/ui/button";
import { useLoading } from "../../../../utils/contexts/LoadingContext";
import {
  CardHeader,
  CardTitle,
  CardDescription,
} from "../../../../components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";

// Icons
import Modal from "../../../../components/reusable/Modal";
import { DatePicker } from "../../../../components/ui/datePicker";

// Styles
// (No styles imported in this selection)

const IngredReprocessPage = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "MENU CREATE REPROCESS INGREDS PAGE",
  });

  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState([]);
  const { loading, setLoading } = useLoading();
  const [change, setChange] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [date, setDate] = useState(null);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [currentPageIngreds, setCurrentPageIngreds] = useState(1);
  const [totalPagesIngreds, setTotalPagesIngreds] = useState(1);

  const today = new Date();
  today.setHours(23, 59, 59, 0);

  function convertDateFormat(inputDate: string): string {
    // Parse the input date string
    const dateObj = new Date(inputDate);

    // Extract year, month, and day components
    const year = dateObj.getFullYear().toString();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // Month starts from 0
    const day = dateObj.getDate().toString().padStart(2, "0");

    // Form the desired output format
    const outputDate = year + month + day;

    return outputDate;
  }

  useEffect(() => {
    setLoading(true);
    let isMounted = true;
    const controller = new AbortController();

    const getGraph = async (url) => {
      try {
        const response = await axiosPrivate.get(url, {
          signal: controller.signal,
        });
        setData(response.data.results.data);
        setTotalPagesIngreds(response.data.results.total_pages);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };

    const apiUrl = `ingred_list?${currentPageIngreds}`;

    getGraph(apiUrl);

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [change, currentPageIngreds]);

  const handleReprocessAll = async () => {
    setLoading(true);
    if (date == null) {
      Toaster.show(
        "Selecione uma data para reprocessar os ingredientes.",
        "error"
      );
      setLoading(false);
      return;
    }

    try {
      const response = await axiosPrivate.post(
        `/reprocess_ingreds/`,
        JSON.stringify({ start_date: convertDateFormat(date), ingred_id: [] })
      );

      if (response.status === 200 || response.status === 201) {
        Toaster.show("Reprocessamento efetuado com sucesso.", "success");
        setLoading(false);
      } else {
        Toaster.show(
          "Erro ao reprocesar ingredientes. Por favor, tente mais tarde.",
          "error"
        );
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      Toaster.show(
        "Erro ao reprocesar ingredientes. Por favor, tente mais tarde.",
        "error"
      );
      setLoading(false);
    }
  };

  const handleReprocessSelected = async () => {
    setLoading(true);
    if (date == null) {
      Toaster.show(
        "Selecione uma data para reprocessar os ingredientes.",
        "error"
      );
      setLoading(false);
      return;
    }

    if (selectedIngredients.length === 0) {
      Toaster.show(
        "Selecione pelo menos um ingrediente para reprocessar.",
        "error"
      );
      setLoading(false);
      return;
    }

    try {
      const response = await axiosPrivate.post(
        `/reprocess_ingreds/`,
        JSON.stringify({
          start_date: convertDateFormat(date),
          ingred_id: selectedIngredients.map((ingredient) => ingredient.id),
        })
      );

      if (response.status === 200 || response.status === 201) {
        Toaster.show("Reprocessamento efetuado com sucesso.", "success");
        setLoading(false);
      } else {
        Toaster.show(
          "Erro ao reprocesar ingredientes. Por favor, tente mais tarde.",
          "error"
        );
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      Toaster.show(
        "Erro ao reprocesar ingredientes. Por favor, tente mais tarde.",
        "error"
      );
      setLoading(false);
    }
  };

  const darkMode = useDarkMode();

  function formatDate(dateString, hour = true) {
    const date = new Date(dateString);
    if (!hour) {
      const formattedDate = date.toLocaleDateString("pt-PT");
      return formattedDate;
    }
    const formattedDate = date.toLocaleDateString("pt-PT", {
      hour: "numeric",
      minute: "numeric",
    });
    return formattedDate;
  }

  const handleCheckboxChange = (ingr_id) => {
    setSelectedIngredients((prevSelectedIngredients) => {
      if (prevSelectedIngredients.find((item) => item.id === ingr_id)) {
        return prevSelectedIngredients.filter((item) => item.id !== ingr_id);
      } else {
        return [...prevSelectedIngredients, { id: ingr_id }];
      }
    });
  };

  return (
    <Page title={"Reprocessar Ingredientes"} loading={loading}>
      {tokenVerified ? (
        <div className="w-[90%] m-auto text-black">
          {/* Header Section */}
          <CardHeader className="mb-5">
            <CardTitle>Reprocessar ingredientes</CardTitle>
            <CardDescription>
              Escolha os Ingredientes cuja configuração teve alterações, quando
              devem ter efeitos e aplique o reprocessamento dos dados (preços).
            </CardDescription>
          </CardHeader>
          <InfoComponent text="">
            <div>
              <p>
                Poderá reprocessar um ou mais ingredientes atualizando valores
                previamente calculados segundo as suas alterações. Poderá
                aplicar as alterações a todos ou apenas aos ingredientes
                selecionados.
              </p>
              <p>
                A data selecionada irá indicar o ponto a partir da qual as
                alterações terão efeito.
              </p>
            </div>
          </InfoComponent>
          <div className="flex justify-end gap-2 mt-5 mb-2">
            <DatePicker date={date} setDate={setDate} maxDate={today} />
            <Button
              onClick={() =>
                Toaster.showWithHandler(
                  date == null
                    ? "Estará a alterar valores calculados a partir de hoje para todas as receitas"
                    : `Estará a alterar valores calculados a partir de: ${formatDate(
                        date,
                        false
                      )} para todas as receitas`,
                  () => handleReprocessAll(),
                  { duration: Infinity }
                )
              }
              id="update-all"
            >
              Atualizar todos
            </Button>
            <Button
              onClick={() =>
                Toaster.showWithHandler(
                  date == null
                    ? "Estará a alterar valores calculados a partir de hoje para todas as receitas"
                    : `Estará a alterar valores calculados a partir de: ${formatDate(
                        date,
                        false
                      )} para as receitas selecionadas`,
                  () => handleReprocessSelected(),
                  { duration: Infinity }
                )
              }
              id="update-selected"
            >
              Atualizar selecionados
            </Button>
          </div>
          {data.length > 0 && (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Nome</TableHead>
                  <TableHead>Classe</TableHead>
                  <TableHead>Descrição</TableHead>
                  <TableHead>Tipo</TableHead>
                  <TableHead>Data Criação</TableHead>
                  <TableHead></TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {data.map((ingredient) => (
                  <TableRow key={ingredient.ingr_id}>
                    <TableCell>{ingredient.ingr_name}</TableCell>
                    <TableCell>{ingredient.ingr_class}</TableCell>
                    <TableCell>{ingredient.ingr_descrip}</TableCell>
                    <TableCell>{ingredient.ingr_degree_process}</TableCell>
                    <TableCell>{formatDate(ingredient.ztimestamp)}</TableCell>
                    <TableCell>
                      <input
                        type="checkbox"
                        checked={
                          selectedIngredients.find(
                            (item) => item.id === ingredient.ingr_id
                          ) !== undefined
                        }
                        onChange={() =>
                          handleCheckboxChange(ingredient.ingr_id)
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "20px",
            }}
          >
            <Paginate
              currentPage={currentPageIngreds}
              setCurrentPage={setCurrentPageIngreds}
              totalPages={totalPagesIngreds}
              loading={loading}
            />
          </div>

          <Modal isOpen={showModal} setIsOpen={setShowModal}>
            <div style={{ height: "100%", margin: "0px" }}>
              <div style={{ height: "100%" }}>
                <div style={{ height: "100%" }}>
                  <DatePicker date={date} setDate={setDate} maxDate={today} />
                </div>
              </div>
            </div>
          </Modal>

          <Loading isOpen={loading} />
        </div>
      ) : (
        // <Loading isOpen={true} />
        <></>
      )}
    </Page>
  );
};

export default IngredReprocessPage;
