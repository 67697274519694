// React
import { useEffect, useState } from "react";
import axios from "axios";

// contexts
import useDarkMode from "../../Profile/usedarkmode";
import { useApi } from "../../../utils/hooks/useAxiosPrivate";

// components
import Page from "../page";
import News from "../../../components/reusable/news/news";
import Loading from "../../../components/reusable/loading/loading";
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";
import {
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import { Button } from "../../../components/ui/button";
import { useLoading } from "../../../utils/contexts/LoadingContext";

// icons / images
import { useNavigate } from "react-router-dom";
import CardComponent from "../../../components/reusable/cardComponent";
import { GiBookCover } from "react-icons/gi";

const HomePage = () => {
  const navigate = useNavigate();
  const darkMode = useDarkMode();
  const [tokenVerified, setTokenVerified] = useState(false);
  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "HOME PAGE",
  });

  const { loading, setLoading } = useLoading();
  const { get, getCache, setCache } = useApi();
  // Dashboard
  const [displayedText, setDisplayedText] = useState("");
  const [nProducts, setNProducts] = useState(0);
  const [nRecipes, setNRecipes] = useState(0);
  const [nPrices, setNPrices] = useState([0, 0]);
  const [nNewProducts, setNNewProducts] = useState([0, 0]);
  const [gptText, setGptText] = useState("");
  const [isTextDisplayed, setIsTextDisplayed] = useState(false);

  useEffect(() => {
    if (isTextDisplayed) return;

    let index = 0;
    const interval = setInterval(() => {
      if (gptText && index < gptText.length) {
        setDisplayedText((prevText) => prevText + gptText.charAt(index));
        index++;
      } else {
        clearInterval(interval);
        setIsTextDisplayed(true);
      }
    }, 10);

    return () => clearInterval(interval);
  }, [gptText, isTextDisplayed]);

  // Dashboarddrg
  useEffect(() => {

    const getKPI = async (endpoint: string) => {
      setLoading(true);
      try {
        const response = await get(endpoint, {
        });
        console.log(response.data);
        setCache(endpoint, response.data);
        setNProducts(response.data.totalProducts);
        setNRecipes(response.data.totalRecipes);
        setNPrices(response.data.collectedPrices);
        setNNewProducts(response.data.newProducts);
        setGptText(response.data.gptSummary);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    const url = "home_dashboard";
    const cachedData = getCache(url);
    if (cachedData) {
      setNProducts(cachedData.totalProducts);
      setNRecipes(cachedData.totalRecipes);
      setNPrices(cachedData.collectedPrices);
      setNNewProducts(cachedData.newProducts);
      setGptText(cachedData.gptSummary);
      setLoading(false);
    } else {
      getKPI(url);
    }
  }, []);

  return (
    <Page title={"Home"} loading={loading}>
      {tokenVerified && (
        <div>
          {!loading && (
            <div className="pb-10">
              <CardHeader>
                <CardTitle>Bem-vindo ao AI4CHEF</CardTitle>
                <CardDescription>
                  Esteja a par de todas as novidades sobre restauração
                </CardDescription>
              </CardHeader>
              <div className="grid xl:grid-cols-2 gap-2">
                <div className="mt-2 mb-2">
                  <News darkMode={darkMode} />
                </div>
                <div className="grid grid-cols-2 gap-4 mt-2">
                  <CardComponent
                    title={"Preços recolhidos últimas 24h"}
                    value={<p>{nPrices[0]}</p>}
                    variation={nPrices[1]}
                  />
                  <CardComponent
                    title={"Produtos"}
                    value={<p>{nProducts}</p>}
                    showVariation={false}
                  />
                  <CardComponent
                    title={"Novos Produtos"}
                    value={<p>{nNewProducts[0]}</p>}
                    variation={nNewProducts[1]}
                  />
                  <CardComponent
                    title={nRecipes && nRecipes > 0 ? "Receitas" : "Sem Receitas Criadas."}
                    value={
                      nRecipes && nRecipes > 0 ? (
                        <p>{nRecipes}</p>
                      ) : (
                        <div
                          className="cursor-pointer items-center flex gap-2 py-6"
                          onClick={() => navigate("/app/menu")}
                        >
                          <GiBookCover size={32} />
                          <p>Gerir Receitas</p>
                        </div>
                      )
                    }
                    showVariation={false}
                  />
                </div>
              </div>

              <div className="py-8">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                  <div
                    onClick={() => navigate("/app/general")}
                    className="bg-white text-black font-bold py-4 px-6 rounded-2xl shadow-lg transform transition-transform duration-300 hover:-translate-y-8 hover:cursor-pointer"
                  >
                    <div
                      className="relative w-full"
                      style={{ paddingBottom: "100%" }}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/home_market.png`}
                        alt="Evolução de Preços"
                        className="absolute top-0 left-0 w-full h-full object-cover rounded-t-2xl"
                      />
                    </div>
                    <CardHeader>
                      <CardTitle className="text-2xl">
                        Evolução de Preços
                      </CardTitle>
                      <CardDescription className="text-sm">
                        Acompanhe a evolução dos preços dos produtos
                      </CardDescription>
                    </CardHeader>
                  </div>
                  <div
                    onClick={() => navigate("/app/sales/short")}
                    className="bg-white text-black font-bold py-4 px-6 rounded-2xl shadow-lg transform transition-transform duration-300 hover:-translate-y-8 hover:cursor-pointer"
                  >
                    <div
                      className="relative w-full"
                      style={{ paddingBottom: "100%" }}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/home_sales.png`}
                        alt="Análise de Vendas"
                        className="absolute top-0 left-0 w-full h-full object-cover rounded-t-2xl"
                      />
                    </div>
                    <CardHeader>
                      <CardTitle className="text-2xl">
                        Análise de Vendas
                      </CardTitle>
                      <CardDescription className="text-sm">
                        Acompanhe a evolução das vendas dos seus produtos
                      </CardDescription>
                    </CardHeader>
                  </div>
                  <div
                    onClick={() => navigate("/app/customers")}
                    className="bg-white text-black font-bold py-4 px-6 rounded-2xl shadow-lg transform transition-transform duration-300 hover:-translate-y-8 hover:cursor-pointer"
                  >
                    <div
                      className="relative w-full"
                      style={{ paddingBottom: "100%" }}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/home_customer.png`}
                        alt="Análise de Consumidores"
                        className="absolute top-0 left-0 w-full h-full object-cover rounded-t-2xl"
                      />
                    </div>
                    <CardHeader>
                      <CardTitle className="text-2xl">
                        Análise de Consumidores
                      </CardTitle>
                      <CardDescription className="text-sm">
                        Explore o comportamento dos seus clientes
                      </CardDescription>
                    </CardHeader>
                  </div>
                  <div
                    onClick={() => navigate("/app/forecasts")}
                    className="bg-white text-black font-bold py-4 px-6 rounded-2xl shadow-lg transform transition-transform duration-300 hover:-translate-y-8 hover:cursor-pointer"
                  >
                    <div
                      className="relative w-full"
                      style={{ paddingBottom: "100%" }}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/home_forecast.png`}
                        alt="Previsão de Vendas"
                        className="absolute top-0 left-0 w-full h-full object-cover rounded-t-2xl"
                      />
                    </div>
                    <CardHeader>
                      <CardTitle className="text-2xl">
                        Previsão de Vendas
                      </CardTitle>
                      <CardDescription className="text-sm">
                        Faça a previsão de vendas com base nos seus dados
                      </CardDescription>
                    </CardHeader>
                  </div>
                </div>
              </div>
            </div>
          )}
          <Loading isOpen={loading} />
        </div>
      )}
    </Page>
  );
};

export default HomePage;

/*
  // Section for displaying various card components
  <div className="col-span-1">
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {nPrices && (
        <CardComponent
          title={"Preços recolhidos últimas 24h"}
          value={`${nPrices[0]}`}
          variation={nPrices[1]}
        ></CardComponent>
      )}
      {nProducts && (
        <CardComponent
          title={"Produtos"}
          value={`${nProducts}`}
          showVariation={false}
        ></CardComponent>
      )}
      {nNewProducts && (
        <CardComponent
          title={"Novos Produtos"}
          value={`${nNewProducts[0]}`}
          variation={nNewProducts[1]}
        ></CardComponent>
      )}
      {nRecipes && (
        <CardComponent
          title={"Receitas"}
          value={`${nRecipes}`}
          showVariation={false}
        ></CardComponent>
      )}
    </div>
  </div>

  // Section for displaying AI assistant text
  <div className="col-span-1">
    {displayedText && (
      <div>
        <div
          className={`p-5 ${
            darkMode ? "bg-[#422653]" : "bg-white"
          } rounded shadow-sm`}
        >
          <div
            className={`text-base ${
              darkMode ? "text-gray-200" : " text-gray-400"
            } `}
          >
            Assistente de AI
          </div>
          <div
            className={`${
              darkMode
                ? "bg-[#422653] text-gray-200"
                : " bg-gray-100 text-gray-500"
            }  rounded mt-3 p-3`}
          >
            {displayedText}
          </div>
        </div>
      </div>
    )}
  </div>
*/
