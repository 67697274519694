// React
import { useEffect, useState } from "react";

// Hooks
import useTokenVerifier from "../../../../utils/hooks/useTokenVerifier";
import useAxiosPrivate from "../../../../utils/hooks/useAxiosPrivate";
import useDarkMode from "../../../Profile/usedarkmode";

// Components
import Page from "../../page";
import Loading from "../../../../components/reusable/loading/loading";
import SelectComponent from "../../../../components/reusable/select/select";
import NWeeksFormatter from "../../../../components/reusable/select/nweeks";
import InfoComponent from "../../../../components/reusable/info/info.component";
import { Button } from "../../../../components/ui/button";

// Icons
import { IoRestaurant } from "react-icons/io5";

// Other
import Slider from "react-input-slider";
import { CardDescription, CardTitle } from "../../../../components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table"; // Import Shadcn table components
import { useLoading } from "../../../../utils/contexts/LoadingContext";

const Pricing = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "MENU PRICING PAGE",
  });

  const axiosPrivate = useAxiosPrivate();
  const { loading, setLoading } = useLoading();
  const [nweeks, setNweeks] = useState<number>(4);
  const [pricings, setPricings] = useState([]);
  const [search, setSearch] = useState(true);

  const [state, setState] = useState({ x: 100, y: 100 });
  useEffect(() => {
    const controller = new AbortController();
    //setLoading(true);
    const getGraph = async (endpoint, params = {}) => {
      const url = params
        ? `pricing${endpoint}/?${Object.entries(params)
            .map(([key, value]) => `${key}=${value}`)
            .join("&")}`
        : `pricing${endpoint}`;
      setLoading(true);

      try {
        const response = await axiosPrivate.get(url, {
          signal: controller.signal,
        });
        setPricings(response.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    if (search) {
      getGraph("", { nweeks, markup: state.x });
      setSearch(false);
    }
  }, [axiosPrivate, search]);

  const darkMode = useDarkMode();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowRight") {
        setState((state) => ({ ...state, x: Math.min(state.x + 5, 1000) }));
      } else if (event.key === "ArrowLeft") {
        setState((state) => ({ ...state, x: Math.max(state.x - 5, 0) }));
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <Page title={"Preço dos Pratos"} loading={loading}>
      {tokenVerified ? (
        <div className="w-[90%] mx-auto min-h-screen">
          <div className="flex justify-between gap-20 items-center">
            <div>
              <CardTitle>Preço dos Pratos</CardTitle>
              <CardDescription className="py-2">
                Preço dos pratos tendo em conta as {nweeks} últimas semanas
              </CardDescription>
            </div>
            <SelectComponent
              formatter={NWeeksFormatter}
              darkMode={darkMode}
              onChange={setNweeks}
            />
          </div>

          <div className="flex justify-between gap-20 items-center pt-10 pb-6">
            <div className="flex flex-col gap-2 w-full">
              <p style={{ color: darkMode ? "#f4f4f4" : "black" }}>
                Markup: {state.x}%
              </p>
              <Slider
                axis="x"
                x={state.x}
                xmin={0}
                xmax={1000}
                xstep={5}
                onChange={({ x }) => setState((state) => ({ ...state, x }))}
                styles={{
                  track: {
                    width: "100%", // Adjust the width as needed
                    maxWidth: "400px",
                  },
                }}
              />
            </div>
            <Button onClick={() => setSearch(true)}>Recalcular</Button>
          </div>
          <div>
            <InfoComponent>
              <div>
                <p>
                  Poderá escolher o markup que pretende obter nos seus pratos
                  através do slider, arraste para o valor pretendido ou use as
                  setas do teclado (direita para aumentar, esquerda para
                  diminuir).
                </p>
                <p>Por defeito considera-se um markup de 100%.</p>
                <br />
                {/* <p>(1) Custo máximo da dose no periodo de {nweeks} semanas</p> */}
                <p>*Custo médio da dose no periodo de {nweeks} semanas</p>
                {/* <p>(3) Custo da dose no dia de hoje</p> */}
              </div>
            </InfoComponent>
          </div>
          <div className="py-6">
            <div className="pb-5">
              <Table>
                <TableHeader>
                  <TableRow>
                    {[
                      "Nome do ingrediente",
                      // "Número de ingredientes",
                      // "Peso da receita(gr)",
                      "Peso da Dose(gr)",
                      // "Custo máximo (1)",
                      "*Custo médio",
                      // "Custo (3)",
                    ].map((header) => (
                      <TableCell key={header}>{header}</TableCell>
                    ))}
                    <TableCell className="bg-gray-50" key={"Novo Preço"}>
                      Novo Preço
                    </TableCell>
                  </TableRow>
                </TableHeader>
                <TableBody
                  style={{ backgroundColor: darkMode ? "#262853" : "" }}
                >
                  {pricings["new_pricing"] &&
                    pricings["new_pricing"].map((item: any, index: any) => (
                      <TableRow key={index}>
                        <TableCell>{pricings["recipe_name"][index]}</TableCell>
                        {/* <TableCell>{pricings["num_ingr"][index]}</TableCell>
                        <TableCell>{pricings["tt_peso"][index]}gr</TableCell> */}
                        <TableCell>
                          {pricings["tt_peso_dose"][index]}gr
                        </TableCell>
                        {/* <TableCell>{pricings["v_max_dose"][index]}€</TableCell> */}
                        <TableCell>
                          {pricings["v_avg_fim_dose"][index]}€
                        </TableCell>
                        {/* <TableCell>{pricings["v_day_dose"][index]}€</TableCell> */}
                        <TableCell className="bg-gray-50">
                          {pricings["new_pricing"][index]}€
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          </div>
          <Loading isOpen={loading} />
        </div>
      ) : (
        // <Loading isOpen={true} />
        <></>
      )}
    </Page>
  );
};

export default Pricing;
