import { useEffect, useMemo, useRef, useState } from "react";
import { Input } from "../../../../components/ui/input";
import { Button } from "../../../../components/ui/button";
import { Label } from "../../../../components/ui/label";
import {
    CardDescription,
    CardTitle,
    CardHeader,
} from "../../../../components/ui/card";
import { useApi } from "../../../../utils/hooks/useAxiosPrivate";
import { useLoading } from "../../../../utils/contexts/LoadingContext";
import Toaster from "../../../../components/reusable/Toaster";
import countryList from 'react-select-country-list'
import Select from "react-select";
import { DialogTitle } from "../../../../components/ui/dialog";
interface ProviderFormProps {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    setChange: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ProviderForm({ setShowModal, setChange }: ProviderFormProps) {
    const { setLoading } = useLoading();
    const { post } = useApi();
    const [providerName, setProviderName] = useState("");
    const [providerNipc, setProviderNipc] = useState("");
    const [providerCountry, setProviderCountry] = useState("");
    const [providerAddress, setProviderAddress] = useState("");
    const [providerPostalCode, setProviderPostalCode] = useState("");
    const [providerContactName, setProviderContactName] = useState("");
    const [providerContactEmail, setProviderContactEmail] = useState("");
    const [providerContactPhone, setProviderContactPhone] = useState("");
    const options = useMemo(() => countryList().getData(), []);


    const validateEmail = (email: string) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const validatePostalCode = (postalCode: string) => {
        const regex = /^\d{4}-?\d{3}$/;
        return regex.test(postalCode);
    };

    const handleSave = async (e: React.FormEvent) => {
        e.preventDefault();
        

        if (!validateEmail(providerContactEmail)) {
            Toaster.show("Email inválido.", "error");
            return;
        }

        if (!validatePostalCode(providerPostalCode)) {
            Toaster.show("Código postal inválido.", "error");
            return;
        }

        if (providerNipc.length !== 9) {
            Toaster.show("NIPC inválido.", "error");
            return;
        }

        setLoading(true);
        const data = {
            suppl_name: providerName,
            suppl_nipc: providerNipc,
            suppl_countrycode: providerCountry,
            suppl_address: providerAddress,
            suppl_postal_code: providerPostalCode,
            suppl_maincontact_name: providerContactName,
            suppl_maincontact_email: providerContactEmail,
            suppl_maincontact_phone: providerContactPhone,
        };

        try {
            const response = await post(
                "/create_supplier/",
                JSON.stringify(data)
            );

            if (response.status === 200 || response.status === 201) {
                Toaster.show("Fornecedor criado com sucesso.", "success");
                setChange(prev => !prev);
                setShowModal(false);
            } else {
                Toaster.show(response.data.detail, "error");
            }
        } catch (error) {
            Toaster.show("Erro ao criar fornecedor.", "error");
        }
        setLoading(false);
    };

    return (
        <form className="space-y-6" onSubmit={handleSave}>
            <div>
                <DialogTitle>
                    <CardHeader>
                        <CardTitle className="text-4xl font-semibold text-blue-dark">
                            Adicionar Fornecedor
                        </CardTitle>
                        <CardDescription>
                            Adicione fornecedores dos seus produtos e compras.
                        </CardDescription>
                    </CardHeader>
                </DialogTitle>
            </div>
            <div>
                <Label className="block text-gray-700 text-sm font-bold mb-1">
                    Nome do Fornecedor
                </Label>
                <div className="mt-2">
                    <Input
                        id="providerName"
                        name="providerName"
                        type="text"
                        placeholder="Digite o nome do fornecedor"
                        value={providerName}
                        onChange={(e) => setProviderName(e.target.value)}
                    />
                </div>
            </div>
            <div>
                <Label className="block text-gray-700 text-sm font-bold mb-1">
                    NIPC do Fornecedor
                </Label>
                <div className="mt-2">
                    <Input
                        id="providerNipc"
                        name="providerNipc"
                        type="text"
                        placeholder="Digite o NIPC do fornecedor"
                        value={providerNipc}
                        onChange={(e) => setProviderNipc(e.target.value)}
                    />
                </div>
            </div>
            <div>
                <Label className="block text-gray-700 text-sm font-bold mb-1">
                    País do Fornecedor
                </Label>
                <div className="mt-2">
                    <Select
                        options={options}
                        value={options.find(option => option.value === providerCountry)}
                        onChange={(option) => setProviderCountry(option ? option.value : "")}
                        placeholder="Selecione o código do país"
                        isClearable
                        required
                    />
                </div>
            </div>
            <div>
                <Label className="block text-gray-700 text-sm font-bold mb-1">
                    Endereço do Fornecedor
                </Label>
                <div className="mt-2">
                    <Input
                        id="providerAddress"
                        name="providerAddress"
                        type="text"
                        placeholder="Digite o endereço do fornecedor"
                        value={providerAddress}
                        onChange={(e) => setProviderAddress(e.target.value)}
                    />
                </div>
            </div>
            <div>
                <Label className="block text-gray-700 text-sm font-bold mb-1">
                    Código Postal do Fornecedor
                </Label>
                <div className="mt-2">
                    <Input
                        id="providerPostalCode"
                        name="providerPostalCode"
                        type="text"
                        placeholder="Digite o código postal do fornecedor"
                        value={providerPostalCode}
                        onChange={(e) => setProviderPostalCode(e.target.value)}
                    />
                </div>
            </div>
            <div>
                <Label className="block text-gray-700 text-sm font-bold mb-1">
                    Nome do Contato
                </Label>
                <div className="mt-2">
                    <Input
                        id="providerContactName"
                        name="providerContactName"
                        type="text"
                        placeholder="Digite o nome do contato"
                        value={providerContactName}
                        onChange={(e) => setProviderContactName(e.target.value)}
                    />
                </div>
            </div>
            <div>
                <Label className="block text-gray-700 text-sm font-bold mb-1">
                    Email do Contato
                </Label>
                <div className="mt-2">
                    <Input
                        id="providerContactEmail"
                        name="providerContactEmail"
                        type="email"
                        placeholder="Digite o email do contato"
                        value={providerContactEmail}
                        onChange={(e) => setProviderContactEmail(e.target.value)}
                    />
                </div>
            </div>
            <div>
                <Label className="block text-gray-700 text-sm font-bold mb-1">
                    Telefone do Contato
                </Label>
                <div className="mt-2">
                    <Input
                        id="providerContactPhone"
                        name="providerContactPhone"
                        type="text"
                        placeholder="Digite o telefone do contato"
                        value={providerContactPhone}
                        onChange={(e) => setProviderContactPhone(e.target.value)}
                    />
                </div>
            </div>
            <div className="flex justify-end">
                <Button type="submit">Guardar</Button>
            </div>
        </form>
    );
}