// React
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Hooks
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";

// API
import { axiosPrivate } from "../api/axios";

const useAxiosPrivate = () => {

  return axiosPrivate;
};

export const useApi = () => {
  const axiosPrivate = useAxiosPrivate();

  function getCacheKey(endpoint: string): string {
    const label = endpoint.replaceAll("/", "_");
    return label;
  }

  function setCache(endpoint: string, data: any): void {
    sessionStorage.setItem(getCacheKey(endpoint), JSON.stringify(data));
  }

  function getCache(endpoint: string): any {
    const cachedData = sessionStorage.getItem(getCacheKey(endpoint));
    return cachedData ? JSON.parse(cachedData) : null;
  }

  function clearCache(endpoint: string): void {
    sessionStorage.removeItem(getCacheKey(endpoint));
  }

  const get = async (url: string, config = {}) => {
    //console.log("GET", url);
    // const cachedData = getCache(url);
    // if (cachedData) {
    //     return cachedData;
    // }
    //console.log("axiosPrivate in useApi =>", axiosPrivate.defaults);

    const response = await axiosPrivate.get(url, config);
    // setCache(url, response);
    return response;
  };

  const post = async (url: string, data: any, config = {}) => {
    return axiosPrivate.post(url, data, config);
  };

  const put = async (url: string, data: any, config = {}) => {
    return axiosPrivate.put(url, data, config);
  };

  const del = async (url: string, config = {}) => {
    return axiosPrivate.delete(url, config);
  };

  return { get, post, put, del, setCache, getCache, clearCache };
};

export default useAxiosPrivate;
