// React
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Hooks
import useTokenVerifier from "../../../../utils/hooks/useTokenVerifier";
import { useApi } from "../../../../utils/hooks/useAxiosPrivate";
import useDarkMode from "../../../Profile/usedarkmode";

// Components
import Page from "../../page";
import InfoComponent from "../../../../components/reusable/info/info.component";
import Loading from "../../../../components/reusable/loading/loading";
import Paginate from "../../../../components/reusable/pagination/paginate";
import Toaster from "../../../../components/reusable/Toaster";
import { useLoading } from "../../../../utils/contexts/LoadingContext";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../../../components/ui/table";

// Icons
import { FaPlusCircle, FaTrash } from "react-icons/fa";
import { IoAdd } from "react-icons/io5";
import { MdHelpOutline } from "react-icons/md";
import { MdOutlineModeEditOutline } from "react-icons/md";
import Modal from "../../../../components/reusable/Modal";
import {
    CardDescription,
    CardTitle,
    CardHeader,
} from "../../../../components/ui/card";
import { Input } from "../../../../components/ui/input";
import BackComponent from "../../../../components/reusable/goBack/go-back-component";


function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("pt-PT", {
        hour: "numeric",
        minute: "numeric",
    });
    return formattedDate;
}

const IngredUsage = () => {
    const [tokenVerified, setTokenVerified] = useState(false);

    // Callback function to update token verification status
    const handleTokenVerified = () => {
        setTokenVerified(true);
    };
    useTokenVerifier({
        onTokenVerified: handleTokenVerified,
        log: "MENU CREATE INGRED PAGE",
    });

    const location = useLocation();
    const { id, name } = location.state || {};

    const { get, post } = useApi();
    const [recipes, setRecipes] = useState(null);
    const { loading, setLoading } = useLoading();
    const [change, setChange] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const navigate = useNavigate();
    const [filteredRecipes, setFilteredRecipes] = useState([]);


    const deleteIngred = async (recipe_id: Number) => {
        if (!id || !recipe_id) return;
        setLoading(true);
        const body = {
            ingr_id: id,
            recipe_id: recipe_id,
        };
        const response = await post(
            `/assign_delete/`,
            JSON.stringify(body)
        );
        if (response.status === 200) {
            Toaster.show("Ingrediente apagado com sucesso.", "success");
            setLoading(false);
            setChange(!change);
        } else {
            setLoading(false);
            Toaster.show("Erro ao apagar ingrediente.", "error");
        }
    };

    useEffect(() => {
        if (!recipes) return;
        if (search === "") {
            setFilteredRecipes(recipes);
            return;
        }
        setFilteredRecipes(
            recipes.filter((recipe) =>
                recipe.recipe_name.toLowerCase().includes(search.toLowerCase())
            )
        );
    }, [search, recipes]);

    useEffect(() => {
        if (!id) {
            Toaster.show("Ingrediente não encontrado.", "error");
            navigate(-1);
        }
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await get(`recipes_by_ingredient?page=${currentPage}`, { params: { ingr_id: id } });
                console.log("RecipesIngred", response.data);
                setRecipes(response.data["results"].recipes);
                setFilteredRecipes(response.data["results"].recipes);
                setTotalPages(response.data["results"].total_pages);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error(error);
            }
        };
        fetchData();
    }, [currentPage, change, id]);


    const darkMode = useDarkMode();

    return (
        <Page title={"Ingredientes"} loading={loading}>
            {tokenVerified ? (
                <div className="min-h-screen">
                    <div className="w-[90%] m-auto text-black">
                        {/* Header Section */}
                        <BackComponent />
                        <div className="flex items-center justify-between">
                            <CardHeader>
                                <CardTitle className="text-4xl font-semibold text-blue-dark">
                                    Receitas que contêm {name}
                                </CardTitle>
                                <CardDescription>
                                    Consulte as receitas em que o ingrediente {name} é utilizado.
                                </CardDescription>
                            </CardHeader>
                        </div>

                        {/* Info Section */}
                        <InfoComponent text="">
                            <div>
                                <p>
                                    Poderá eliminar o ingrediente {name} de uma receita clicando no respetivo ícone
                                </p>
                            </div>
                        </InfoComponent>
                        <div className="py-4">
                            <Input
                                type="text"
                                name="search"
                                placeholder="Pesquisar"
                                className="w-80 h-10"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>

                        {filteredRecipes.length > 0 ? (
                            <div>
                                <Table>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHead>Nome</TableHead>
                                            <TableHead>Classe</TableHead>
                                            <TableHead>Descrição</TableHead>
                                            <TableHead>Estado</TableHead>
                                            <TableHead>Data Criação</TableHead>
                                            <TableHead></TableHead>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody
                                        style={{ backgroundColor: darkMode ? "#262853" : "" }}
                                        className="bg-white divide-y divide-gray-200"
                                    >
                                        {filteredRecipes.map((recipe) => (
                                            <TableRow key={recipe.recipe_id}>
                                                <TableCell>{recipe.recipe_name}</TableCell>
                                                <TableCell>{recipe.recipe_class}</TableCell>
                                                <TableCell>{recipe.recipe_descrip}</TableCell>
                                                <TableCell>{recipe.recipe_status}</TableCell>
                                                <TableCell>{formatDate(recipe.ztimestamp)}</TableCell>
                                                <TableCell className="flex gap-4">
                                                    <FaTrash
                                                        onClick={() =>
                                                            Toaster.showWithHandler(
                                                                `Estará a remover o ingrediente ${name} da receita ${recipe.recipe_name}. Deseja continuar?`,
                                                                () => deleteIngred(recipe.recipe_id),
                                                                { duration: Infinity }
                                                            )
                                                        }
                                                        title="Eliminar"
                                                        className="text-red-300 hover:text-red-500 text-xl cursor-pointer hover:scale-125 transition-transform duration-30"
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        ) : (
                            <div
                                style={{ color: darkMode ? "white" : "black" }}
                                className="text-center text-2xl mt-10"
                            >
                                {`Sem receitas para o ingrediente ${name} ${(search && recipes.length > 0) ? `que contenham ${search} no nome` : ""}` }
                            </div>
                        )}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingTop: "20px",
                        }}
                    >
                        {filteredRecipes.length > 0 && <Paginate
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalPages={totalPages}
                            loading={!recipes}
                        />}
                    </div>
                    <Loading isOpen={loading} />
                </div>
            ) : (
                // <Loading isOpen={true} />
                <></>
            )}
        </Page>
    );
};

export default IngredUsage;
