// React
import { useEffect, useRef, useState } from "react";

// Hooks
import useTokenVerifier from "../../../../utils/hooks/useTokenVerifier";
import useAxiosPrivate from "../../../../utils/hooks/useAxiosPrivate";
import useDarkMode from "../../../Profile/usedarkmode";

// Components
import Page from "../../page";
import InfoComponent from "../../../../components/reusable/info/info.component";
import Loading from "../../../../components/reusable/loading/loading";
import Paginate from "../../../../components/reusable/pagination/paginate";
import Toaster from "../../../../components/reusable/Toaster";
import { Button } from "../../../../components/ui/button";

// Icons
import { DatePicker } from "../../../../components/ui/datePicker";
import { useLoading } from "../../../../utils/contexts/LoadingContext";
import {
  CardHeader,
  CardTitle,
  CardDescription,
} from "../../../../components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";

const Reprocess = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "MENU CREATE REPROCESS RECIPES PAGE",
  });

  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState([]);
  const { loading, setLoading } = useLoading();
  const [showModal, setShowModal] = useState(false);
  const [date, setDate] = useState(null);
  const [selectedRecipes, setSelectedRecipes] = useState([]);
  const [currentPageRecipes, setCurrentPageIngreds] = useState(1);
  const [totalPagesIngreds, setTotalPagesRecipes] = useState(1);

  const today = new Date();
  today.setHours(23, 59, 59, 0);

  const datetime = useRef<null | any>(null);
  function convertDateFormat(inputDate: string): string {
    // Parse the input date string
    const dateObj = new Date(inputDate);

    // Extract year, month, and day components
    const year = dateObj.getFullYear().toString();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // Month starts from 0
    const day = dateObj.getDate().toString().padStart(2, "0");

    // Form the desired output format
    const outputDate = year + month + day;

    return outputDate;
  }

  useEffect(() => {
    setLoading(true);
    let isMounted = true;
    const controller = new AbortController();

    const getGraph = async (url) => {
      try {
        const response = await axiosPrivate.get(url, {
          signal: controller.signal,
        });
        setData(response.data.results.recipes);

        setTotalPagesRecipes(response.data.results.total_pages);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };

    const apiUrl = `recipe_list?page=${currentPageRecipes}`;

    getGraph(apiUrl);

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [currentPageRecipes]);

  const handleReprocessAll = async () => {
    setLoading(true);
    if (date == null) {
      Toaster.show("Selecione uma data para reprocessar os receitas.", "error");
      setLoading(false);
      return;
    }

    try {
      const response = await axiosPrivate.post(
        `/reprocess_recipes/`,
        JSON.stringify({ start_date: convertDateFormat(date), recipe_id: [] })
      );

      if (response.status === 200 || response.status === 201) {
        Toaster.show("Reprocessamento efetuado com sucesso.", "success");
        setLoading(false);
      } else {
        Toaster.show(
          "Erro ao reprocesar receitas. Por favor, tente mais tarde.",
          "error"
        );
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      Toaster.show(
        "Erro ao reprocesar receitas. Por favor, tente mais tarde.",
        "error"
      );
      setLoading(false);
    }
  };

  const handleReprocessSelected = async () => {
    setLoading(true);
    if (date == null) {
      Toaster.show("Selecione uma data para reprocessar os receitas.", "error");
      setLoading(false);
      return;
    }

    if (selectedRecipes.length === 0) {
      Toaster.show(
        "Selecione pelo menos um recipee para reprocessar.",
        "error"
      );
      setLoading(false);
      return;
    }

    try {
      const response = await axiosPrivate.post(
        `/reprocess_recipes/`,
        JSON.stringify({
          start_date: convertDateFormat(date),
          recipe_id: selectedRecipes.map((recipe) => recipe.id),
        })
      );

      if (response.status === 200 || response.status === 201) {
        Toaster.show("Reprocessamento efetuado com sucesso.", "success");
        setLoading(false);
      } else {
        Toaster.show(
          "Erro ao reprocesar receitas. Por favor, tente mais tarde.",
          "error"
        );
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      Toaster.show(
        "Erro ao reprocesar receitas. Por favor, tente mais tarde.",
        "error"
      );
      setLoading(false);
    }
  };

  function formatDate(dateString, hour = true) {
    const date = new Date(dateString);
    if (!hour) {
      const formattedDate = date.toLocaleDateString("pt-PT");
      return formattedDate;
    }
    const formattedDate = date.toLocaleDateString("pt-PT", {
      hour: "numeric",
      minute: "numeric",
    });
    return formattedDate;
  }

  const handleCheckboxChange = (recipe_id) => {
    setSelectedRecipes((prevSelectedRecipes) => {
      if (prevSelectedRecipes.find((item) => item.id === recipe_id)) {
        return prevSelectedRecipes.filter((item) => item.id !== recipe_id);
      } else {
        return [...prevSelectedRecipes, { id: recipe_id }];
      }
    });
  };

  return (
    <Page title={"Reprocessar Receitas"} loading={loading}>
      {tokenVerified ? (
        <div className="w-[90%] m-auto text-black">
          {/* Header Section */}
          <CardHeader className="mb-5">
            <CardTitle>Reprocessar Receitas</CardTitle>
            <CardDescription>
              Escolha as Receitas cuja configuração teve alterações, quando
              devem ter efeitos e aplique o reprocessamento dos dados (preços).
            </CardDescription>
          </CardHeader>
          <InfoComponent text="">
            <div>
              <p>
                Poderá reprocessar uma ou mais receitas atualizando valores
                previamente calculados segundo as suas alterações. Poderá
                aplicar as alterações a todos ou apenas as receitas
                selecionadas.
              </p>
              <p>
                A data selecionada irá indicar o ponto a partir da qual as
                alterações terão efeito.
              </p>
            </div>
          </InfoComponent>

          <div className="flex justify-end gap-2 mt-5 mb-2">
            <DatePicker date={date} setDate={setDate} maxDate={today} />
            <Button
              onClick={() =>
                Toaster.showWithHandler(
                  date == null
                    ? "Estará a alterar valores calculados a partir de hoje para todas as receitas"
                    : `Estará a alterar valores calculados a partir de: ${formatDate(
                        date,
                        false
                      )} para todas as receitas`,
                  () => handleReprocessAll(),
                  { duration: Infinity }
                )
              }
              id="update-all"
            >
              Atualizar todos
            </Button>
            <Button
              onClick={() =>
                Toaster.showWithHandler(
                  date == null
                    ? "Estará a alterar valores calculados a partir de hoje para todas as receitas"
                    : `Estará a alterar valores calculados a partir de: ${formatDate(
                        date,
                        false
                      )} para as receitas selecionadas`,
                  () => handleReprocessSelected(),
                  { duration: Infinity }
                )
              }
              id="update-selected"
            >
              Atualizar selecionados
            </Button>
          </div>

          {data.length > 0 && (
            <Table className="min-w-full divide-y divide-gray-200 shadow-2xl rounded">
              <TableHeader>
                <TableRow>
                  <TableHead>Nome</TableHead>
                  <TableHead>Classe</TableHead>
                  <TableHead>Descrição</TableHead>
                  <TableHead>Data Criação</TableHead>
                  <TableHead></TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {data.map((recipe) => (
                  <TableRow key={recipe.recipe_id}>
                    <TableCell>{recipe.recipe_name}</TableCell>
                    <TableCell>{recipe.recipe_class}</TableCell>
                    <TableCell>{recipe.recipe_descrip}</TableCell>
                    <TableCell>{formatDate(recipe.ztimestamp)}</TableCell>
                    <TableCell>
                      <input
                        type="checkbox"
                        className="bg-blue-500 text-white"
                        checked={
                          selectedRecipes.find(
                            (item) => item.id === recipe.recipe_id
                          ) !== undefined
                        }
                        onChange={() => handleCheckboxChange(recipe.recipe_id)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          <div className="flex justify-center pt-5">
            <Paginate
              currentPage={currentPageRecipes}
              setCurrentPage={setCurrentPageIngreds}
              totalPages={totalPagesIngreds}
              loading={data.length <= 0}
            />
          </div>
          <Loading isOpen={loading} />
        </div>
      ) : (
        // <Loading isOpen={true} />
        <></>
      )}
    </Page>
  );
};

export default Reprocess;
