import React, { useState, useEffect } from "react";

// Hooks
import useAxiosPrivate from "../../../../utils/hooks/useAxiosPrivate";
import useDarkMode from "../../../Profile/usedarkmode";
import { useUser } from "../../../../utils/contexts/UserContext";

// Components
import InfoComponent from "../../../../components/reusable/info/info.component";
import Toaster from "../../../../components/reusable/Toaster";
import { Input } from "../../../../components/ui/input";
import { Button } from "../../../../components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
import { useLoading } from "../../../../utils/contexts/LoadingContext";

// Icons
import { IoAdd, IoTrash } from "react-icons/io5";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { Label } from "../../../../components/ui/label";

interface CategoriesShopSetup {
  onPrevious?: () => void;
  onSubmit?: () => void;
}

const CategoriesShopSetup: React.FC<CategoriesShopSetup> = ({
  onPrevious,
  onSubmit,
}) => {
  const { user } = useUser();
  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState([]);
  const { loading, setLoading } = useLoading();
  const [modalCreate, setModalVisibleCreate] = useState(false);
  const [changedList, setChangedList] = useState(false);
  const [shop_category, setProd_category] = useState("");
  const [shop_category_info, setProd_category_info] = useState("");



  // CREATE TABLE IF NOT EXISTS ai4chef_v1.ai4chef_cfg_rest_purch_account_type
  // (
  // purch_account_type_id integer NOT NULL GENERATED BY DEFAULT AS IDENTITY ( INCREMENT 1 START 1 MINVALUE 1 MAXVALUE 2147483647 CACHE 1 ),
  // purch_account_type character varying(200) COLLATE pg_catalog."default" NOT NULL,
  // purch_account_type_status character varying(15) COLLATE pg_catalog."default" NOT NULL,
  // purch_account_type_info character varying(250) COLLATE pg_catalog."default",
  // ztimestamp timestamp with time zone NOT NULL,
  // rest_site_id integer NOT NULL,
  // purch_account_type_id_parent integer ,
  // CONSTRAINT ai4chef_cfg_rest_purch_account_type_pkey PRIMARY KEY (purch_account_type_id)
  // )
  // TABLESPACE pg_default;




  useEffect(() => {

    // Get categories from the backend
    const getCategories = async () => {
      try {
        const response = await axiosPrivate.get("get_all_purch_account_types");
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    getCategories();

  }, [changedList]);

  // Delete category from the backend
  const deleteCategory = async (purch_account_type_id: string) => {
    const response = await axiosPrivate.post(
      `/delete_purch_account_type/`,
      JSON.stringify({ purch_account_type_id: purch_account_type_id })
    );

    if (response.status === 204) {
      Toaster.show("Categoria apagada com sucesso.", "success");
      setLoading(true);
      setChangedList(!changedList);
    } else {
      Toaster.show("Erro ao apagar categoria.", "error");
      setLoading(false);
    }
  };

  // Create category from the backend
  const handleSubmit = async () => {
    if (shop_category === "") {
      Toaster.show("Preencha os campos.", "error");
      return;
    }

    const data = {
      purch_account_type: shop_category,
      purch_account_type_info: shop_category_info,
      purch_account_type_status: "ACTIVE",
    };
    try {
      const response = await axiosPrivate.post(
        `/create_purch_account_type/`,
        JSON.stringify(data)
      );

      if (response.status === 201) {
        setLoading(true);
        setChangedList(!changedList);
        Toaster.show("Categoria guardada com sucesso.", "success");
      } else {
        Toaster.show("Erro ao adicionar categoria.", "error");
      }
    } catch (error) {
      Toaster.show("Erro ao adicionar categoria.", "error");
    }
  };


  return (
    <div className="px-4">
      {!loading && (
        <div className="">
          <div className="flex items-center space-x-4 mt-auto justify-end">
            <Button onClick={() => setModalVisibleCreate(true)}>
              <IoAdd size={16} className="mr-2" />
              Categoria
            </Button>
            {/* {user.profile && (
              <Button onClick={handleUpdate}>Reclassificar produtos</Button>
            )} */}
          </div>
          {data.length > 0 &&
            <Table className="mt-4">
              <TableHeader>
                <TableRow>
                  <TableHead>Nome</TableHead>
                  <TableHead>Informação</TableHead>
                  <TableHead></TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {data.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.purch_account_type}</TableCell>
                    <TableCell>{item.purch_account_type_info}</TableCell>
                    <TableCell>
                      <IoTrash
                        size={18}
                        onClick={() => deleteCategory(item.purch_account_type_id)}
                        className="text-red-500 text-2xl hover:scale-125 transition-transform duration-300 ml-auto"
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>}
          <InfoComponent text="">
            <div>
              <p>
                Defina as categorias das suas compras. Deste modo terá
                análises focadas para cada uma das suas categorias.
              </p>
            </div>
          </InfoComponent>
          <div className="flex justify-between mt-5 mb-2">
            {onPrevious && <Button onClick={onPrevious}>Anterior</Button>}
            <Button onClick={onSubmit} className="ml-auto" disabled={loading}>
              Guardar
            </Button>
          </div>
        </div>
      )}
      <Dialog open={modalCreate} onOpenChange={setModalVisibleCreate}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Nova Categoria</DialogTitle>
            <DialogDescription>
              Defina o nome e a descrição da nova categoria.
            </DialogDescription>
          </DialogHeader>
          <div className="my-4">
            <Label>Nome da Categoria</Label>
            <Input
              value={shop_category}
              onChange={(e) => setProd_category(e.target.value)}
              placeholder="Nome da Categoria"
            />
          </div>
          <div className="mb-4">
            <Label>Descrição da Categoria</Label>
            <Input
              value={shop_category_info}
              onChange={(e) => setProd_category_info(e.target.value)}
              placeholder="Descrição da Categoria"
            />
          </div>
          <div className="flex justify-end">
            <Button
              onClick={() => {
                setModalVisibleCreate(false);
                handleSubmit();
              }}
            >
              Submeter Categoria
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CategoriesShopSetup;
