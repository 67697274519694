// React
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";



// components
import Page from "../page";
import Loading from "../../../components/reusable/loading/loading";
import Toaster from "../../../components/reusable/Toaster";
import {
  exportToPDF,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";

// icons / images
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";

// hooks
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";
import useDarkMode from "../../Profile/usedarkmode";
import { useApi } from "../../../utils/hooks/useAxiosPrivate";
import { IoIosSearch } from "react-icons/io";
import { FaDownload } from "react-icons/fa";
import { useLoading } from "../../../utils/contexts/LoadingContext";

const Planning = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "CUSTOM PLANNING PAGE",
  });

  const { get } = useApi();
  const [plans, setPlans] = useState({
    q2: null,
    q3: null,
    q4: null,
  });

  const [plansFilter, setPlansFilter] = useState({
    q2: null,
    q3: null,
    q4: null,
  });
  const [plan_config, setPlanConfig] = useState([]);
  const { loading, setLoading } = useLoading();
  const location = useLocation();
  const darkMode = useDarkMode();

  const [searchQueries, setSearchQueries] = useState({
    q2: "",
    q3: "",
    q4: "",
  });

  const idPlan =
    location.pathname.match(/\/app\/planning\/custom\/(\d+)/)?.[1] || 0;

  const titles = {
    q2: "Quantidades usadas nas receitas - Valores médios de PVP e estimativa de custo",
    q3: "Lista de compras de ingredientes segundo o critério - SKU de Referência",
    q4: " Lista de compras de ingredientes segundo o critério - Top 3 melhores preços",
  };

  const columnDefinitions = {
    q2: [
      { headerName: "Nome", field: "ingr_name" },
      { headerName: "Quantidade(kg)", field: "qt_ingr_kg" },
      { headerName: "Preço(kg)", field: "price_avg" },
      { headerName: "Custo Estimado", field: "custo_estimado" },
    ],

    q3: [
      { headerName: "Ingrediente", field: "ingr_name" },
      { headerName: "Quantidade(kg)", field: "qt_ingr_kg" },
      { headerName: "Detalhes", field: "detailed_sku_name" },
      { headerName: "Fornecedor", field: "sku_prov_name" },
      { headerName: "Categoria", field: "sku_category" },
      { headerName: "Preço", field: "price" },
      { headerName: "Data do Preço", field: "price_date" },
      { headerName: "Custo Estimado", field: "custo_estimado" },
    ],
    q4: [
      { headerName: "Ingrediente", field: "ingr_name" },
      { headerName: "Quantidade(kg)", field: "qt_ingr_kg" },
      { headerName: "Detalhes", field: "detailed_sku_name" },
      { headerName: "Fornecedor", field: "sku_prov_name" },
      { headerName: "Categoria", field: "sku_category" },
      { headerName: "Preço", field: "price" },
      { headerName: "Data do Preço", field: "price_date" },
      { headerName: "Custo Estimado", field: "custo_estimado" },
      { headerName: "Rank", field: "rank" },
    ],
  };
  useEffect(() => {
    const fetchPlans = async () => {
      const controller = new AbortController();
      try {
        const response = await get(`get_plan/?an_id=${idPlan}`, {
          signal: controller.signal,
        });
        setPlanConfig(response.data.recipe_doses);
        const data = {
          q2: response.data.q2,
          q3: response.data.q3,
          q4: response.data.q4,
        };



        setPlans(data);
        setPlansFilter(data);
        console.log(data);
      } catch (error) {
        console.error(error);
        Toaster.show("Erro ao carregar planeamento à medida", "error");
      } finally {
        setLoading(false);
      }
      return () => controller.abort();
    };

    fetchPlans();
  }, [idPlan]);

  useEffect(() => {
    const updateTableDataFilter = (key) => {
      if (!plans || !plans[key]) return;

      switch (key) {
        case "q2":
          setPlansFilter((prev) => ({
            ...prev,
            q2: plans.q2.filter((item) =>
              item.ingr_name.toLowerCase().includes(searchQueries.q2.toLowerCase())
            ),
          }));
          break;
        case "q3":
          setPlansFilter((prev) => ({
            ...prev,
            q3: plans.q3.filter((item) =>
              item.ingr_name.toLowerCase().includes(searchQueries.q3.toLowerCase())
            ),
          }));
          break;
        case "q4":
          setPlansFilter((prev) => ({
            ...prev,
            q4: plans.q4.filter((item) =>
              item.ingr_name.toLowerCase().includes(searchQueries.q4.toLowerCase())
            ),
          }));
          break;
        default:
          break;
      }
    };

    Object.keys(searchQueries).forEach((key) => {
      updateTableDataFilter(key);
    });
  }, [searchQueries, plans]);

  const renderSearchBar = (key) => (

    <div className="flex gap-2">
      <Input
        type="text"
        name="search"
        placeholder="Pesquisar"
        className="w-80 h-10"
        value={searchQueries[key]}
        onChange={(e) =>
          setSearchQueries((prev) => ({ ...prev, [key]: e.target.value }))
        }
      />
      <Button type="submit">
        <IoIosSearch className="text-white" size={20} />
      </Button>
    </div>
  );

  const renderTableHeaderConfig = () => (

    <TableHeader>
      <TableRow>
        {["Nome da Receita", "Número de doses"].map((header) => (
          <TableCell
            className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
            style={{ background: darkMode ? "#422655" : "" }}

            key={header}
          >
            {header}
          </TableCell>
        ))}
      </TableRow>
    </TableHeader>
  );

  const renderTableBodyConfig = () => (
    <TableBody
    >
      {plan_config.map((item, index) => (
        <TableRow key={index} className="px-6 py-2 whitespace-no-wrap text-gray-600"
        >
          <TableCell  >
            {item[2]}
          </TableCell>
          <TableCell  >
            {item[1]}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );

  const renderTable = (key) => (
    <div >
      <h3 className="text-2xl font-semibold text-blue-dark">{titles[key]}</h3>
      <hr className="my-4" />
      <div className="pb-4 flex gap-2 justify-between items-center">
        {renderSearchBar(key)}
        <Button
          className="cursor-pointer"
          onClick={() =>
            exportToPDF(
              columnDefinitions[key],
              plansFilter[key],
              titles[key]
            )
          }
        >
          Exportar PDF
        </Button>
      </div>
      <div className="overflow-x-auto">
        {plansFilter[key] && <Table className="min-w-full">
          {renderTableHeader(
            columnDefinitions[key].map((col) => col.headerName)
          )}
          {renderTableBody(
            columnDefinitions[key].map((col) => col.field),
            plansFilter[key]
          )}
        </Table>}
      </div>

    </div>
  );

  const renderTableHeader = (columnNames: string[]) => (
    <TableHeader>
      <TableRow>
        {columnNames.map((header) => (
          <TableCell
            key={header}
            style={{ background: darkMode ? "#422655" : "" }}
            className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
          >
            {header}
          </TableCell>
        ))}
      </TableRow>
    </TableHeader>
  );

  const renderTableBody = (fields: string[], data: any[]) => (
    <TableBody
      style={{ backgroundColor: darkMode ? "#262853" : "" }}
      className="bg-white divide-y divide-gray-200"
    >
      {data.map((item, index) => {
        const getBackgroundColor = (rank) => {
          switch (rank) {
            case 1:
              return "rgba(144, 238, 144, 0.5)";
            case 2:
              return "rgba(173, 255, 47, 0.5)";
            case 3:
              return "rgba(255, 238, 140, 0.5)";
            default:
              return "";
          }
        };

        return (
          <TableRow
            key={index}
            style={{ backgroundColor: getBackgroundColor(item.rank) }}
          >
            {fields.map((field, fieldIndex) => {
              let value = item[field];
              if (
                (field.includes("price") || field.includes("custo")) &&
                !field.includes("date")
              ) {
                value = `${Number(value).toFixed(2)}€`;
              }
              return (
                <TableCell
                  key={fieldIndex}
                  className="px-6 py-2 whitespace-no-wrap text-gray-600"
                >
                  {value}
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </TableBody>
  );

  const renderContent = () => {
    if (loading) return <Loading isOpen={loading} />;
    if (!plans) {
      return (
        <div
          style={{ color: darkMode ? "white" : "black" }}
          className="text-center  text-2xl mt-10"
        >
          Sem planeamentos de compras. Crie um novo planeamento.
        </div>
      );
    }
    return (
      <div style={{ paddingBottom: "100px" }} className="flex gap-8 flex-col">
        <div>
          <h3 className="text-2xl font-semibold text-blue-dark">Planeamento à medida para a seguinte configuração</h3>
          <hr className="my-4" />
          {plans["q2"] && <Table  >
            {renderTableHeaderConfig()}
            {renderTableBodyConfig()}
          </Table>}
        </div>

        {Object.keys(plans).map((key) => (
          <React.Fragment key={key}>
            {plans[key] &&
              plans[key].length > 0 &&
              renderTable(key)}
          </React.Fragment>
        ))}
      </div>
    );
  };

  return (
    <Page title={"Planeamento à Medida"} loading={loading}>
      {tokenVerified ? (
          <div className="ion-padding">
            <div>
              <div
                style={{
                  paddingBottom: "30px",
                  paddingLeft: "50px",
                  paddingRight: "50px",
                }}
              >
                <div>{renderContent()}</div>
              </div>
            </div>
            <Loading isOpen={!plans["q2"] && !plans["q3"] && !plans["q4"]} />
          </div>
          
      ) : (
        // <Loading isOpen={true} />
        <></>
      )}
    </Page>
  );
};

export default Planning;
