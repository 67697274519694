// React
import { useState, useEffect, useRef } from "react";

// contexts
import useDarkMode from "../../Profile/usedarkmode";
import { useApi } from "../../../utils/hooks/useAxiosPrivate";
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";

// components
import Page from "../page";
import ChartComponent from "../../../components/reusable/charts/chartComponent";
import SelectComponent from "../../../components/reusable/select/select";
import MonthsFormatter from "../../../components/reusable/select/months";
import KpiFormatter from "../../../components/reusable/select/kpi";
import Loading from "../../../components/reusable/loading/loading";
import CardComponent from "../../../components/reusable/cardComponent";
import { useLoading } from "../../../utils/contexts/LoadingContext";

import {
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card"; // Import Card components
import { differenceInDays, format } from "date-fns";

type ChartOptions = Record<string, any>;

const SalesPage = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "SALES PAGE - LONGTERM",
  });

  const { loading, setLoading } = useLoading();
  const { get } = useApi();
    const [lastDate, setLastDate] = useState(null);
  const [nweeks, setNweeks] = useState<number>(4);
  const [month, setMonth] = useState("Jan");
  const [kpi, setKpi] = useState("quantity");
  const chartRef = useRef(null);
  const [graphData, setGraphData] = useState<Record<string, ChartOptions>>({
    q41_1: {},
    q41_2: {},
    q42_1: {},
    q42_2: {},
    q43_1: {},
    q43_2: {},
    q44_1: {},
    q44_2: {},
  });
  const [kpis, setKpis] = useState({
    absolute: [0, 0, 0, 0],
    variation: [0, 0, 0, 0],
  });
  const darkMode = useDarkMode();

  function handleChartFinished() {
    if (chartRef.current) {
      chartRef.current.getEchartsInstance().resize();
    }
  }

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      return format(date, "dd-MM-yyyy");
    };
  
    const getColorBasedOnDate = (dateString: string) => {
      const date = new Date(dateString);
      const today = new Date();
      const diffInDays = differenceInDays(today, date);
  
      const green = [0, 255, 0];
      const yellow = [255, 255, 0];
      const red = [255, 0, 0];
  
      let color;
      if (diffInDays <= 7) {
        color = green;
      } else if (diffInDays <= 14) {
        const ratio = (diffInDays - 7) / 7;
        color = yellow.map((value, index) => Math.round(value * ratio + green[index] * (1 - ratio)));
      } else {
        const ratio = (diffInDays - 14) / 14;
        color = red.map((value, index) => Math.round(value * ratio + yellow[index] * (1 - ratio)));
      }
  
      return `${color[0]}, ${color[1]}, ${color[2]}`;
    };

  useEffect(() => {
    const controller = new AbortController();

    const fetchGraphData = async () => {
      try {
        const promises = [
          get("dash4_sales_last_date"),
          get(`dash4_q41_1/?kpi=${kpi}`),
          get(`dash4_q41_2/?kpi=${kpi}`),
          get(`dash4_q42_1/?month=${month}&kpi=${kpi}`),
          get(`dash4_q42_2/?month=${month}&kpi=${kpi}`),
          get(`dash4_q43_1/?month=${month}&kpi=${kpi}`),
          get(`dash4_q43_2/?month=${month}&kpi=${kpi}`),
          get(`dash4_q44_1/?month=${month}&kpi=${kpi}`),
          get(`dash4_q44_2/?month=${month}&kpi=${kpi}`),
        ];

        const responses = await Promise.all(promises);
        const [
          lastDateData,
          q41_1Data,
          q41_2Data,
          q42_1Data,
          q42_2Data,
          q43_1Data,
          q43_2Data,
          q44_1Data,
          q44_2Data,
        ] = responses;

        setGraphData({
          q41_1: q41_1Data.data,
          q41_2: q41_2Data.data,
          q42_1: q42_1Data.data,
          q42_2: q42_2Data.data,
          q43_1: q43_1Data.data,
          q43_2: q43_2Data.data,
          q44_1: q44_1Data.data,
          q44_2: q44_2Data.data,
        });
        setLastDate(lastDateData.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    const fetchKpiData = async () => {
      try {
        const response = await get(`get_sales_kpi/?nweeks=${52}`);
        setKpis(response.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    if (nweeks) {
      fetchGraphData();
      fetchKpiData();
    }

    return () => {
      controller.abort();
    };
  }, [nweeks, month, kpi]);

  return (
    <Page loading={loading}>
      {tokenVerified ? (
        <div className="ion-padding">
          <div>
            <div className="flex justify-between mb-5">
              <CardHeader>
                <CardTitle>Análise de Vendas a Longo Prazo</CardTitle>
                <CardDescription>
                  Compare as vendas do seu restaurante com períodos homólogos.
                </CardDescription>
              </CardHeader>
              <div className="mt-5">
                <SelectComponent
                  formatter={KpiFormatter}
                  darkMode={darkMode}
                  onChange={setKpi}
                />
              </div>
            </div>

            {kpis && (
              <div>
                <div className="grid items-center gap-2 lg:grid-cols-2 xl:grid-cols-5">
                  <CardComponent
                    title={/* Placeholder for Title */ "Total de Vendas"}
                    value={`${kpis["absolute"][1]}€`}
                    variation={kpis["variation"][1]}
                  />
                  <CardComponent
                    title={/* Placeholder for Title */ "Nº Total de Faturas"}
                    value={`${kpis["absolute"][0]}`}
                    variation={kpis["variation"][0]}
                  />
                  <CardComponent
                    title={/* Placeholder for Title */ "Valor médio p/Fatura"}
                    value={`${kpis["absolute"][3]}€`}
                    variation={kpis["variation"][3]}
                  />
                  <CardComponent
                    title={/* Placeholder for Title */ "Itens vendidos"}
                    value={`${kpis["absolute"][2]}`}
                    variation={kpis["variation"][2]}
                  />
                                    <CardComponent
                    title={"Últimos dados disponíveis"}
                    value={formatDate(lastDate)}
                    showVariation={false}
                    color={getColorBasedOnDate(lastDate)}

                  />
                </div>
              </div>
            )}

            <div className="grid xl:grid-cols-2 gap-4 padding">
              <ChartComponent
                chartRef={chartRef}
                option={graphData.q41_1}
                handleChartFinished={handleChartFinished}
                ChangeScale={false}
              />
              <ChartComponent
                chartRef={chartRef}
                option={graphData.q41_2}
                handleChartFinished={handleChartFinished}
                ChangeScale={false}
              />
              <div className="xl:col-span-2 py-10">
                <SelectComponent
                  formatter={MonthsFormatter}
                  darkMode={darkMode}
                  onChange={setMonth}
                />
              </div>
            </div>
            <div className="grid xl:grid-cols-3 gap-2 padding">
              <ChartComponent
                chartRef={chartRef}
                option={graphData.q42_1}
                handleChartFinished={handleChartFinished}
                ChangeScale={false}
              />
              <ChartComponent
                chartRef={chartRef}
                option={graphData.q43_1}
                handleChartFinished={handleChartFinished}
                ChangeScale={false}
              />
              <ChartComponent
                chartRef={chartRef}
                option={graphData.q44_1}
                handleChartFinished={handleChartFinished}
                ChangeScale={false}
              />
              <ChartComponent
                chartRef={chartRef}
                option={graphData.q42_2}
                handleChartFinished={handleChartFinished}
                ChangeScale={false}
              />
              <ChartComponent
                chartRef={chartRef}
                option={graphData.q43_2}
                handleChartFinished={handleChartFinished}
                ChangeScale={false}
              />
              <ChartComponent
                chartRef={chartRef}
                option={graphData.q44_2}
                handleChartFinished={handleChartFinished}
                ChangeScale={false}
              />
            </div>
          </div>
          <Loading isOpen={loading} />
        </div>
      ) : (
        // <Loading isOpen={true} />
        <></>
      )}
    </Page>
  );
};

export default SalesPage;
