import { useApi } from "../../../utils/hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { axiosPrivate } from "../../../utils/api/axios";
import { loadStripe } from "@stripe/stripe-js";

function Payment() {
  const { productId } = useParams<{ productId: string }>();
  const [product, setProduct] = useState<any>(null);
  const [error, setError] = useState("");
  const { post } = useApi();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axiosPrivate.get(
          `/get-stripe-product/${productId}`
        );
        setProduct(response.data);
        createCheckoutSession(response.data.price_id);
      } catch (err) {
        setError("Failed to fetch product details.");
        console.error(err);
      }
    };

    fetchProduct();
  }, [productId]);

  const createCheckoutSession = async (priceId) => {
    try {
      const response = await post("/create-checkout-session/", {
        price_id: priceId,
        customer_name: "Customer Name",
        customer_vat: "VAT123456",
      });
      console.log("Checkout session response:", response);

      const { sessionId } = response.data;
      // Redirect to Stripe Checkout
      const stripe = await loadStripe(
        "pk_live_51QGMs3Rtm03rkjycc6ysYjdn4NTZ89iA8vyjxWxl2GDl5unaOVEGTDtP0eXkTi5ZusllfEnTZRjyuzlppqPQd8HW00aENysaNB"
      );
      await stripe.redirectToCheckout({ sessionId });
    } catch (err) {
      console.error("Error creating checkout session:", err);
      setError("Failed to create checkout session.");
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      {product && (
        <button
          className="stripe-button"
          onClick={() => createCheckoutSession(product.price_id)}
        ></button>
      )}
    </>
  );
}

export default Payment;
