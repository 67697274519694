export interface QueryColumn {
    headerName: string;
    field: string;
}

export interface QueryConfig {
    columns: QueryColumn[];
    title: string;
    searchMessage: string;
    responseType: string;
    showCategory: boolean;
    requiresDateSearch?: boolean;
}

export const getQueryConfig = (nConsulta: number): QueryConfig | undefined => {
    return QUERY_CONFIGS[nConsulta];
};

export const requiresDateSearch = (nConsulta: number): boolean => {
    const config = getQueryConfig(nConsulta);
    return config?.requiresDateSearch ?? false;
};

export const QUERY_CONFIGS: Record<number, QueryConfig> = {
    1: {
        columns: [
            { headerName: "Nome", field: "username" },
            { headerName: "Cargo", field: "user_comments" },
            { headerName: "Email", field: "email" },
            { headerName: "Estado", field: "user_status" },
        ],
        title: "Consulta dos dados próprios do Cliente",
        searchMessage: "Pesquisar por nome do funcionário",
        responseType: "funcionários",
        showCategory: false
    },
    2: {
        columns: [
            { headerName: "Nome da Receita", field: "recipe_name" },
            { headerName: "Descrição", field: "recipe_descrip" },
            { headerName: "Doses servidas", field: "recipe_order" },
            { headerName: "Utilização", field: "recipe_usage" },
        ],
        title: "Consulta de Receitas",
        searchMessage: "Pesquisar por nome da receita",
        responseType: "receitas",
        showCategory: false
    },
    3: {
        columns: [
            { headerName: "Produto", field: "recipe_name" },
            { headerName: "Doses servidas", field: "recipe_order" },
            { headerName: "Preço(€)", field: "last_foodcost" },
            { headerName: "Data do Preço", field: "last_date_foodcost" },
        ],
        title: "Consulta últimos valores de custo de Receitas",
        searchMessage: "Pesquisar por nome da receita",
        responseType: "receitas",
        showCategory: false
    },
    4: {
        columns: [
            { headerName: "Nome do Ingrediente", field: "ingr_name" },
            { headerName: "Processamento", field: "ingr_degree_process" },
            { headerName: "Preço(€)", field: "last_foodcost" },
            { headerName: "Data do Preço", field: "last_date_foodcost" },
            { headerName: "Consultar", field: "consultarItem" },
        ],
        title: "Consulta últimos valores de custo de Ingredientes",
        searchMessage: "Pesquisar por nome do ingrediente",
        responseType: "ingredientes",
        showCategory: false
    },
    5: {
        columns: [
            { headerName: "Produto", field: "sku_prod_name" },
            { headerName: "Fornecedor", field: "sku_prov_name" },
            { headerName: "Categoria", field: "sku_category" },
            { headerName: "Preço(€)", field: "last_sku_price" },
            { headerName: "Data do Preço", field: "last_date_sku_price" },
            { headerName: "Consultar", field: "consultarItem" },
        ],
        title: "Consulta últimos valores de custo de SKUs Ativos",
        searchMessage: "Pesquisar por nome do produto",
        responseType: "produtos",
        showCategory: true
    },
    6: {
        columns: [
            { headerName: "Produto", field: "sku_prod_name" },
            { headerName: "Fornecedor", field: "sku_prov_name" },
            { headerName: "Categoria", field: "sku_category" },
            { headerName: "Preço(€)", field: "last_sku_price" },
            { headerName: "Data do Preço", field: "last_date_sku_price" },
            { headerName: "Consultar", field: "consultarItem" },
        ],
        title: "Consulta últimos valores de custo de SKUs Não Ativos",
        searchMessage: "Pesquisar por nome do produto",
        responseType: "produtos",
        showCategory: true
    },
    7: {
        columns: [
            { headerName: "Nome do Script", field: "scriptname" },
            { headerName: "Tipo de Log", field: "log_type" },
            { headerName: "Estado da operação", field: "log_oper_status" },
            { headerName: "Utilizador", field: "username" },
            { headerName: "Data", field: "zdata" },
            { headerName: "Descrição da operação", field: "log_oper_descr" },
            { headerName: "Observações", field: "log_obs" },
        ],
        title: "Consulta de logs dos Scripts",
        searchMessage: "Pesquisar por nome do script",
        responseType: "logs",
        showCategory: false
    },
    8: {
        columns: [
            { headerName: "Restaurante", field: "restaurant" },
            { headerName: "Utilizador", field: "user" },
            { headerName: "Número de acessos", field: "num_accesses" },
            { headerName: "Último acesso", field: "last_access_date" },
            { headerName: "Consultar", field: "consultarItem" },
        ],
        title: "Consulta de logs dos Utilizadores",
        searchMessage: "Período de pesquisa (meses)",
        responseType: "logs",
        showCategory: false
    },
    9: {
        columns: [
            { headerName: "Data", field: "date_field" },
            { headerName: "Pingo Doce", field: "1" },
            { headerName: "Continente", field: "2" },
            { headerName: "Mini Preço", field: "3" },
            { headerName: "Makro", field: "4" },
        ],
        title: "Consulta da Recolha Diária de dados recolhidos pelos scrapers por fornecedores",
        searchMessage: "Pesquisar por nome do utilizador",
        responseType: "dados",
        showCategory: false
    },
    10: {
        columns: [
            { headerName: "Data", field: "date_field" },
            { headerName: "Fornecedor", field: "sku_provider_id" },
            { headerName: "Preços scraper", field: "raw" },
            { headerName: "Preços limpos", field: "clean" },
            { headerName: "Preços finais", field: "fact" },
            { headerName: "Variação entre scraper e limpos", field: "delta1" },
            { headerName: "Variação entre limpos e finais", field: "delta2" },
        ],
        title: "Consulta do processo de Recolha Diária de Preços SKU por fornecedores",
        searchMessage: "Pesquisar por nome do utilizador",
        responseType: "preços",
        showCategory: false
    },
    11: {
        columns: [
            { headerName: "Categoria do SKU", field: "sku_category" },
            { headerName: "Número de SKUs", field: "count" },
        ],
        title: "Consulta de todos os SKUs e SKUs classificados via GPT",
        searchMessage: "Pesquisar por nome do utilizador",
        responseType: "SKUs",
        showCategory: false
    },
    12: {
        columns: [
            { headerName: "Data", field: "sku_dt_ref" },
            { headerName: "Categoria do SKU", field: "sku_category" },
            { headerName: "Número de SKUs novos", field: "count" },
        ],
        title: "Consulta de SKUs novos e SKUs classificados via GPT",
        searchMessage: "Pesquisar por nome do utilizador",
        responseType: "SKUs",
        showCategory: false
    },
    13: {
        columns: [
            { headerName: "Id", field: "ingr_id" },
            { headerName: "Nome", field: "ingr_name" },
            { headerName: "Nome técnico", field: "ingr_name_tecn" },
            { headerName: "Descrição", field: "ingr_descrip" },
            { headerName: "Preço Média à Data", field: "ingr_price_avg" },
        ],
        title: "Consulta de Preço de Ingredientes - Valores Diários",
        searchMessage: "Pesquisar por nome do utilizador",
        responseType: "ingredientes",
        showCategory: false,
        requiresDateSearch: true
    },
    14: {
        columns: [
            { headerName: "Ano/Semana", field: "dt_anosemana" },
            { headerName: "Id do Ingrediente", field: "ingr_id" },
            { headerName: "Nome do Ingrediente", field: "ingr_name" },
            { headerName: "Média de preço", field: "price_avg" },
            { headerName: "Id do restaurante", field: "rest_site_id" },
        ],
        title: "Consulta de Preço de Ingredientes - Valores Semanais",
        searchMessage: "Pesquisar por nome do utilizador",
        responseType: "ingredientes",
        showCategory: false
    },
    15: {
        columns: [
            { headerName: "Data", field: "price_date" },
            { headerName: "Id da Receita", field: "recipe_id" },
            { headerName: "Nome da Receita", field: "recipe_name" },
            { headerName: "Preço por kg", field: "recipe_price_kg" },
            { headerName: "Id do restaurante", field: "rest_site_id" },
        ],
        title: "Consulta de Preços de Receitas diários",
        searchMessage: "Pesquisar por nome do utilizador",
        responseType: "receitas",
        showCategory: false
    },
    16: {
        columns: [
            { headerName: "Ano/Semana", field: "dt_anosemana" },
            { headerName: "Id da Receita", field: "recipe_id" },
            { headerName: "Nome da Receita", field: "recipe_name" },
            { headerName: "Média de preço", field: "recipe_price_avg" },
        ],
        title: "Consulta de Preços de Receitas - Valores Semanais",
        searchMessage: "Pesquisar por nome do utilizador",
        responseType: "receitas",
        showCategory: false
    },
    17: {
        columns: [
            { headerName: "Data de referência", field: "dt_ref" },
            { headerName: "Id do fornecedor", field: "sku_provider_id" },
            { headerName: "Total de Registos", field: "total_registos" },
            { headerName: "Observações", field: "sku_price_obs" },
        ],
        title: "Consulta de Preços de SKUs",
        searchMessage: "Pesquisar por data de referência",
        responseType: "SKUs",
        showCategory: false,
        requiresDateSearch: true
    },
    18: {
        columns: [
            { headerName: "Data de referência", field: "dt_ref" },
            { headerName: "Id do Ingrediente", field: "ingr_id" },
            { headerName: "Nome do Ingrediente", field: "ingr_name" },
            { headerName: "Preço Médio", field: "avg_price" },
        ],
        title: "Consulta de Preços de Ingredientes",
        searchMessage: "Pesquisar por data de referência",
        responseType: "ingredientes",
        showCategory: false,
        requiresDateSearch: true
    },
    19: {
        columns: [
            { headerName: "Data de referência", field: "dt_ref" },
            { headerName: "Id da Receita", field: "recipe_id" },
            { headerName: "Nome da Receita", field: "nome" },
            { headerName: "Preço Médio", field: "avg_price" },
        ],
        title: "Consulta de Preços de Receitas",
        searchMessage: "Pesquisar por data de referência",
        responseType: "receitas",
        showCategory: false,
        requiresDateSearch: true
    },
    20: {
        columns: [
            { headerName: "Ano/Semana", field: "dt_anosemana" },
            { headerName: "Número total de registos semanais de SKUs", field: "total_reg_sku_wk" },
            { headerName: "Número total de registos semanais de deltas para os SKUs", field: "total_reg_sku_wk_mdelta" },
            { headerName: "Número total de registos semanais de categorias SKUs", field: "total_reg_ctg_sku_wk" },
            { headerName: "Número total de registos semanais de deltas para as categorias SKUs", field: "total_reg_ctg_sku_wk_mdelta" },
        ],
        title: "Consulta da Disponibilidade de Estatísticas Semanais SKUs",
        searchMessage: "Pesquisar por data de referência",
        responseType: "estatísticas",
        showCategory: false,
        requiresDateSearch: true
    },
    21: {
        columns: [
            { headerName: "Ano/Semana", field: "dt_anosemana" },
            { headerName: "Número total de registos semanais de ingredientes", field: "total_reg_ingr_wk" },
            { headerName: "Número total de registos multi-semanais de ingredientes", field: "total_reg_ingr_wk_mdelta" },
            { headerName: "Número total de registos semanais de receitas", field: "total_reg_recipe_wk" },
            { headerName: "Número total de registos semanais de deltas para as receitas", field: "total_reg_recipe_wk_delta" },
        ],
        title: "Consulta da Disponibilidade de Estatísticas Semanais Ingredientes e Receitas",
        searchMessage: "Pesquisar por data de referência",
        responseType: "estatísticas",
        showCategory: false,
        requiresDateSearch: true
    },
    22: {
        columns: [
            { headerName: "Id do SKU", field: "sku_id" },
            { headerName: "Data de referência", field: "sku_dt_ref" },
            { headerName: "Descrição do Produto", field: "sku_key_name" },
            { headerName: "Unidades por pacote", field: "sku_pack_units" },
            { headerName: "Peso por unidade", field: "sku_unit_weight" },
            { headerName: "Observações", field: "obs" },
            { headerName: "Tipo de conversão", field: "sku_convert_type" },
            { headerName: "Tipo de preço", field: "sku_price_type" },
            { headerName: "Uso da Conversão", field: "convert_use_case" },
        ],
        title: "Consulta de Casos de SKU Ovos Naturais e da configuração aplicada",
        searchMessage: "Pesquisar por data de referência",
        responseType: "configuração",
        showCategory: false,
    }
};