// React
import { useEffect, useState } from "react";

// Hooks
import useAxiosPrivate from "../../../../utils/hooks/useAxiosPrivate";

// Components
import InfoComponent from "../../../../components/reusable/info/info.component";
import Toaster from "../../../../components/reusable/Toaster";
import { Button } from "../../../../components/ui/button";
import "../../../../assets/css/customScroll.styles.css";
import { Input } from "../../../../components/ui/input";
import { useLoading } from "../../../../utils/contexts/LoadingContext";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
const AssignProd = ({ recipe_id = 0, modalCloser, setChange, change, recipe_name }) => {
  // loadingSetter
  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const word = recipe_name.split(" ")[0];
  const [search, setSearch] = useState(word);
  const [selectedProd, setselectedProd] = useState(undefined);
  const { setLoading } = useLoading();

  useEffect(() => {
    const controller = new AbortController();
    const getGraph = async (endpoint, params = {}) => {
      setLoading(true);
      const url = params
        ? `${endpoint}/?${Object.entries(params)
          .map(([key, value]) => `${key}=${value}`)
          .join("&")}`
        : `${endpoint}`;
      try {
        const response = await axiosPrivate.get(url, {
          signal: controller.signal,
        });
        setData(response.data);
        setFilteredData(response.data);
        setLoading(false);
        // loadingSetter(false);
      } catch (error) {

        Toaster.show("Erro ao listar produtos.", "error");
        setLoading(false);
      }
    };
      getGraph("rest_prods_list", { search: "" });

    return () => {
      controller.abort();
    };
  }, []);


  const handleCheckboxChange = (prod_id) => {
    setselectedProd(prod_id);
  };

  const handleAssign = async () => {
    // loadingSetter(true);
    if (!selectedProd) {
      Toaster.show("Selecione um produto", "error");
      // loadingSetter(false);
      return;
    }

    const prodToAssign = {
      rest_prod: selectedProd,
      recipe: recipe_id,
      ass_state: "ACTIVE",
    };

    try {
      const response = await axiosPrivate.post(
        `/prod_asign_create_or_update/`,
        JSON.stringify(prodToAssign)
      );

      if (response.status === 200 || response.status === 201) {
        Toaster.show("Produto associado com sucesso.", "success");
      } else {
        Toaster.show("Erro ao associar produto.", "error");
        // loadingSetter(false);
      }
    } catch (error) {
      console.error("Error:", error);
      Toaster.show("Erro ao associar produto.", "error");
      // loadingSetter(false);
    }
    setChange(!change);
  };

  useEffect(() => {
    setFilteredData(
      data.filter((ingredient) =>
        ingredient.rest_prod_desc.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, data]);

  return (
    <>
      <div>
        <div>
          <div
            className="pt-6"
          >
              <Input
                type="text"
                name="search"
                placeholder="Pesquisar"
                className="w-80 h-10"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
          </div>
        </div>

        <InfoComponent text="">
          <p>
            Associe um produto da sua carta à receita. Isto permite à aplicação
            estudar a performance da suas receitas.
            Pesquise por um produto para adicionar.
          </p>
        </InfoComponent>

        <div className="overflow-auto max-h-96 scroll">
          {filteredData.length > 0 ? (
            <Table>
              <TableHeader >
                <TableRow>
                  <TableHead>
                    Nome
                  </TableHead>
                  <TableHead>
                    Select
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredData.map((prod) => (
                  <TableRow key={prod.rest_prod_id}>
                    <TableCell>
                      {prod.rest_prod_desc}
                    </TableCell>
                    <TableCell>
                      <input
                        type="checkbox"
                        checked={selectedProd === prod.rest_prod_id}
                        onChange={() => handleCheckboxChange(prod.rest_prod_id)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <p>Nenhum produto encontrado para a pesquisa: {search}.</p>
          )}
        </div>

        {filteredData.length > 0 && <div className="pt-6 flex justify-end">
          <Button
            type="submit"
            onClick={() => {
              modalCloser(false);
              handleAssign();
            }}
          >
            Associar produto da carta
          </Button>
        </div>}
      </div>
    </>
  );
};

export default AssignProd;
