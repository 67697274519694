// React
import React, { useState, useMemo } from "react";

// Hooks
import useTokenVerifier from "../../../../utils/hooks/useTokenVerifier";
import useDarkMode from "../../../Profile/usedarkmode";
import { useNavigate } from "react-router-dom";

// Components
import Page from "../../page";
import { Table, TableHeader, TableBody, TableRow, TableCell } from "../../../../components/ui/table";
import {
    CardDescription,
    CardHeader,
    CardTitle,
} from "../../../../components/ui/card";
// Icons
import { FaSearch } from "react-icons/fa";
import { useUser } from "../../../../utils/contexts/UserContext";

const QueriesPageBackOffice = () => {
    const [tokenVerified, setTokenVerified] = useState(false);
    const darkMode = useDarkMode();
    const { user } = useUser();
    const navigate = useNavigate();

    useTokenVerifier({
        onTokenVerified: () => setTokenVerified(true),
        log: "QUERIES PAGE",
    });

    const columns = useMemo(
        () => [
            { headerName: "Consulta", field: "consulta" },
            { headerName: "Descrição", field: "descricao" },
            { headerName: "Consultar", field: "consultar" },
        ],
        []
    );


    const backofficeRows = useMemo(
        () => [
            {
                id: "7",
                consulta: "Consulta de logs dos Scripts",
                descricao: "Consultar os logs dos scripts",

            },
            {
                id: "8",
                consulta: "Consulta de logs dos Utilizadores",
                descricao: "Consultar os logs dos utilizadores",

            },
            {
                id: "9",
                consulta:
                    "Consulta da Recolha Diária de dados recolhidos pelos scrapers por fornecedores",
                descricao:
                    "Consultar os dados de Recolha Diária recolhidos pelos scrapers por fornecedores",

            },
            {
                id: "10",
                consulta:
                    "Consulta do processo de Recolha Diária de Preços SKU por fornecedores",
                descricao:
                    "Consultar os dados do processo de Recolha Diária de Preços SKU por fornecedores",

            },
            {
                id: "11",
                consulta: "Consulta de todos os SKUs e SKUs classificados via GPT",
                descricao: "Consultar todos os SKUs",

            },
            {
                id: "12",
                consulta: "Consulta de SKUs novos e SKUs classificados via GPT",
                descricao: "Consultar os SKUs novos e SKUs classificados via GPT",

            },
            {
                id: "20",
                consulta: "Consulta Estatísticas Semanais SKUs",
                descricao:
                    "Consultar as estatísticas semanais SKUs num determinado período de tempo",

            },
            {
                id: "21",
                consulta: "Consulta Estatísticas Semanais Ingredientes e Receitas",
                descricao:
                    "Consultar as estatísticas semanais de ingredientes e receitas num determinado período de tempo",

            },
            {
                id: "22",
                consulta: "Consulta de Casos de SKU Ovos Naturais e da configuração aplicada",
                descricao:
                    "Consultar os Casos de SKU Ovos Naturais e a configuração aplicada",

            },

        ],
        []
    );



    return (
        <Page title="Consultas" loading={false}>
            {tokenVerified ? (
                <div className="min-h-screen h-full">
                    <div className="pb-10">
                        <CardHeader>
                            <CardTitle>Consultas</CardTitle>
                            <CardDescription>
                                Selecione uma consulta da lista abaixo para visualizar os dados correspondentes.
                            </CardDescription>
                        </CardHeader>
                    </div>
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableCell
                                    style={{
                                        backgroundColor: darkMode ? "#422653" : "",
                                        color: darkMode ? "white" : "",
                                    }}
                                >
                                    Identificador
                                </TableCell>
                                {columns.map((column, index) => (
                                    <TableCell
                                        key={index}
                                        style={{
                                            backgroundColor: darkMode ? "#422653" : "",
                                            color: darkMode ? "white" : "",
                                        }}
                                    >
                                        {column.headerName}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {backofficeRows.map((row, rowIndex) => (
                                <TableRow key={rowIndex}>
                                    <TableCell key={rowIndex}>
                                        {rowIndex + 1}
                                    </TableCell>
                                    {columns.map((column, colIndex) => (
                                        <TableCell key={colIndex}>
                                            {row[column.field]}
                                        </TableCell>
                                    ))}
                                    <TableCell>
                                        <FaSearch
                                            className="cursor-pointer"
                                            title="Consultar"
                                            onClick={() => navigate(`/app/consultasBack/consulta?id=${row.id}`, { state: { nConsulta: row.id } })}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            ) : (
                // <Loading isOpen={true} />
                <></>
            )}
        </Page>
    );
};

export default QueriesPageBackOffice;